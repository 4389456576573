var Validator = require('jsonschema').Validator
var v = new Validator()

// Benchmark
var benchmarkSchema = {
  id: '/Benchmark',
  type: 'object',
  required: ['id', 'kurztext', 'text', 'farbe_id'],
  properties: {
    id: {
      type: 'number'
    },
    kurztext: {
      type: 'string'
    },
    text: {
      type: 'string'
    },
    farbe_id: {
      type: 'number'
    }
  }
}

// Gruppen Werte
var gruppenWerteSchema = {
  id: '/Gruppenwerte',
  type: 'array',
  items: {
    type: 'object',
    required: [
      'kategorie_id',
      'gruppe_id',
      'werte',
      'gesamtAntworten',
      'gesamtKeineAngabe'
      // benchmark_id ist nicht required, da nicht alle Fragen Benchmarks haben
    ],
    properties: {
      kategorie_id: {
        type: 'number'
      },
      gruppe_id: {
        type: 'number'
      },
      werte: {
        $ref: '/Werte'
      },
      // wird in der Grafik unter "n" angezeigt
      gesamtAntworten: {
        type: 'number'
      },
      // wird in der Grafik unter "k.A." angezeigt
      gesamtKeineAngabe: {
        type: 'number'
      },
      benchmark_id: {
        type: 'number'
      }
    }
  }
}

// Werte
var werteSchema = {
  id: '/Werte',
  type: 'array',
  items: {
    type: 'object',
    required: ['antwort_id', 'absolut', 'relativ'],
    properties: {
      antwort_id: {
        type: 'number'
      },
      absolut: {
        type: 'number'
      },
      relativ: {
        type: 'number'
      }
    }
  }
}

// Fragen
var fragenSchema = {
  id: '/Fragen',
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    required: ['id', 'frage', 'typ', 'antworten'],
    /**
     * ENTWEDER die Frage enthält Unterfragen (Linien oder "große" Balken)
     * ODER es gibt direkt das gruppen_werte Array für "kleine" Balken
     * ODER es gibt direkt das werte Array für Kuchengrafik
     */
    oneOf: [
      {
        required: ['unterfragen']
      },
      {
        required: ['gruppen_werte']
      },
      {
        required: ['werte']
      }
    ],
    properties: {
      id: {
        type: 'number'
      },
      frage: {
        type: 'string'
      },
      praefix: {
        type: 'string'
      },
      typ: {
        type: 'string'
      },
      // Nur bei "kleinen" Balkengrafiken ohne Unterfragen
      gruppen_werte: {
        $ref: '/Gruppenwerte'
      },
      // Nur bei Kuchengrafik
      werte: {
        $ref: '/Werte'
      },
      unterfragen: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'frage', 'gruppen_werte'],
          properties: {
            id: {
              type: 'number'
            },
            frage: {
              type: 'string'
            },
            gruppen_werte: {
              $ref: '/Gruppenwerte'
            }
          }
        }
      },
      antworten: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'antwort', 'reihenfolge', 'farbe_id'],
          properties: {
            id: {
              type: 'number'
            },
            antwort: {
              type: 'string'
            },
            reihenfolge: {
              type: 'number'
            },
            farbe_id: {
              type: 'number'
            }
          }
        }
      },
      // Nur wenn auch Pfeile angezeigt werden sollen
      pfeile: {
        type: 'object',
        properties: {
          links: {
            type: 'object',
            required: ['text', 'farbe_id'],
            properties: {
              text: {
                type: 'string'
              },
              farbe_id: {
                type: 'number'
              }
            }
          },
          rechts: {
            type: 'object',
            required: ['text', 'farbe_id'],
            properties: {
              text: {
                type: 'string'
              },
              farbe_id: {
                type: 'number'
              }
            }
          }
        }
      }
    }
  }
}

// Kategorien
var kategorienSchema = {
  id: '/Kategorien',
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    required: ['id', 'name', 'gruppen'],
    properties: {
      // ID der Kategorien UND der Gruppen müssen einzigartig sein.
      // Kategorie mit ID = 1 => es darf keine Gruppe mit ID = 1 geben!
      id: {
        type: 'number'
      },
      name: {
        type: 'string'
      },
      gruppen: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'object',
          required: ['id', 'name', 'farbe_id'],
          properties: {
            id: {
              type: 'number'
            },
            name: {
              type: 'string'
            },
            // = true => Diese Gruppe ist immer ausgewählt
            standard: {
              type: 'boolean'
            },
            farbe_id: {
              type: 'number'
            }
          }
        }
      }
    }
  }
}

// Farben
var farbenSchema = {
  id: '/Farben',
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'farbe'],
    properties: {
      id: { type: 'number' },
      farbe: {
        type: 'string'
        // pattern: '^rgb\\(([0-9]{1,3}), ?[0-9]{1,3}, ?[0-9]{1,3}\\)$'
      },
      //* für Liniengrafik:
      // z.B. [2,3] = 2 Pixel gezeichnet, 3 Pixel frei, usw.
      gestrichelt: {
        type: 'array',
        minItems: 2,
        maxItems: 2,
        items: { type: 'number' }
      },
      dicke: { type: 'number' }
    }
  }
}

// Gesamte Datei
var schema = {
  type: 'object',
  required: ['fragen', 'kategorien', 'farben'],
  properties: {
    fragen: { $ref: '/Fragen' },
    kategorien: { $ref: '/Kategorien' },
    farben: { $ref: '/Farben' },
    benchmarks: {
      type: 'array',
      items: { $ref: '/Benchmark' }
    }
  }
}

v.addSchema(werteSchema, werteSchema.id)
v.addSchema(gruppenWerteSchema, gruppenWerteSchema.id)
v.addSchema(fragenSchema, fragenSchema.id)
v.addSchema(kategorienSchema, kategorienSchema.id)
v.addSchema(farbenSchema, farbenSchema.id)
v.addSchema(benchmarkSchema, benchmarkSchema.id)

export default function validate(json) {
  return v.validate(json, schema)
}
