<template>
  <v-app class="app-content">
    <v-app-bar
      app
      v-if="!fullscreen"
      height="78"
      color="white"
      class="px-4 px-sm-8 px-md-16"
    >
      <v-row align="center">
        <v-col cols="auto">
          <a href="https://bmq-evaluation.com/" target="_blank"
            ><v-img contain max-width="160" src="./assets/logo.png"></v-img
          ></a>
        </v-col>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-col v-if="!!user && user.name" cols="auto" class="d-none d-sm-flex">
          Results Survey {{ user.name }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="!!user" cols="auto">
          <v-btn v-if="!user.isAdmin" class="mr-4" @click="goHome" width="100"
            >Home</v-btn
          >
          <v-btn @click="logout" width="100">Logout</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="main-content">
      <v-row v-if="!hideThumbnail" no-gutters>
        <v-col cols="12">
          <v-img
            v-if="$vuetify.breakpoint.width < 650"
            src="./assets/header-w350.png"
            max-height="200"
          />
          <v-img
            v-if="
              $vuetify.breakpoint.width >= 650 &&
              $vuetify.breakpoint.width < 1100
            "
            src="./assets/header-w650.png"
            max-height="200"
          />
          <v-img
            v-if="$vuetify.breakpoint.width >= 1100"
            src="./assets/header-w1100.png"
            max-height="200"
          />
        </v-col>
      </v-row>

      <router-view></router-view>
      <v-snackbar :value="error" app color="red accent-2" elevation="4">
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="dismissError"> Schließen </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer app padless v-if="!fullscreen">
      <v-card flat tile width="100%" class="text-center" color="bmq-footer">
        <v-card-text class="white--text">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto"> Copyright ©BMQ Evaluation & Consulting </v-col>
            <v-col cols="auto">
              <a
                href="https://bmq-evaluation.com/impressum/"
                target="_blank"
                class="footer-link"
                >Impressum</a
              >
              <a
                href="https://bmq-evaluation.com/datenschutzerklaerung/"
                target="_blank"
                class="footer-link"
                >Datenschutzerklärung</a
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { useSettingsStore } from './stores/settings'
import { useComStore } from './stores/communication'
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
export default {
  name: 'App',
  components: {},
  setup() {
    const settingsStore = useSettingsStore()
    const comStore = useComStore()
    const { fullscreen } = storeToRefs(settingsStore)
    const { user, error } = storeToRefs(comStore)

    const currentRoute = useRoute()
    const router = useRouter()
    const hideThumbnail = computed(() => currentRoute.name === 'Viewer')

    function logout() {
      comStore.logout()
      router.push({ name: 'Login' })
    }

    return {
      fullscreen,
      hideThumbnail,
      snackbarTimeout: 3000,
      user,
      logout,
      error,
      dismissError: () => (error.value = null),
      goHome: () => router.push({ name: 'Welcome' })
    }
  }
}
</script>

<style>
.bmq-text-color {
  color: #005475;
}
.bmq-footer-color {
  background-color: #3a3a3a;
}
.legend-text {
  font-size: 14px;
}

html,
body {
  height: 100%;
}

.rotate-helper {
  /* transform: rotate3d(1, 1, 1, 0.01deg); */
  will-change: transform;
}

.app-content {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
  /* height: calc(100vh - 78px - 54px); */
}

.clickable:hover {
  cursor: pointer;
}

.footer-link {
  text-decoration: none;
  color: white !important;
  padding: 0px 5px;
}

.page-filler {
  height: 100px;
}
.faded {
  opacity: 0.25;
}
.line-legend:hover {
  cursor: pointer;
}

svg {
  line-height: 0px;
}
</style>
