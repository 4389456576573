import { BAR_CONST, COLORS_CONST } from '../constants'
// import { makeId } from '../helper'
/**
 * @function drawBarBackground
 * @param {object} container - Der SVG() Container
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Zeilenhöhe
 * @returns {object} - bar-svgObject
 */
export function drawBarBackground(container, yPos, rowHeight) {
  return container
    .rect(BAR_CONST.chartAreaWidth, rowHeight - BAR_CONST.whitespaceOffset * 2)
    .fill(COLORS_CONST.background)
    .move(BAR_CONST.chartAreaX, yPos + BAR_CONST.whitespaceOffset)
}
