//@ts-check
import { SVG } from '@svgdotjs/svg.js'
import { useChartStore } from '../../stores/chart'

const chartContainerId = '#chartContainer'

/**
 * Erstellt ein <svg> Element mit ID und Klassen
 * @function createSVG
 * @param {string|null} id - ID des SVG Elementes
 * @param {string|Array<string>|null} classes - Klasse(n) des SVG Elementes
 * @returns {object} SVG Element
 */
export const createSVG = (id = null, classes = null) => {
  var svg = SVG().addTo(chartContainerId).size('100%', '100%')
  if (id) svg.id(id)
  if (Array.isArray(classes)) {
    classes.forEach((c) => {
      svg.addClass(c)
    })
  } else if (classes) {
    svg.addClass(classes)
  }
  return svg
}

/**
 * Aktualisiert ein <svg> Element in Breite, Höhe und Styles
 * @function updateSVG
 * @param {object} svg - SVG Element
 * @param {number} width - neue Breite des SVG Elementes
 * @param {number} height - neue Höhe des SVG Elementes
 * @param {object|Array<object>} styles - Style Objekte
 */
export const updateSVG = (svg, width, height, styles = null) => {
  const chartStore = useChartStore()
  const zoomFactor = chartStore.zoomFactor
  svg.viewbox(0, 0, width, height)
  svg.attr('viewBox-origin', `0 0 ${width} ${height}`)
  svg.attr('width', width * zoomFactor)
  svg.attr('width-origin', width)
  // svg.attr('preserveAspectRatio', 'xMidYMid meet')
  svg.attr('height', height * zoomFactor)
  if (styles) {
    svg.attr('style', objectToString(styles))
    svg.attr('style-origin', objectToString(styles))
  }
}

/**
 * Aktualisiert ein <svg> Element in Breite, Höhe und Styles
 * @function zoomSVG
 * @param {object} svg - SVG Element
 * @param {number} zoomFactor - Zoom Faktor
 */
export const zoomSVG = (svg, zoomFactor) => {
  const originBox = svg.getAttribute('viewBox-origin')
  const originStyle = svg.getAttribute('style-origin')

  if (!originBox) return

  let singleBoxValues = originBox.split(' ').map((v) => v * zoomFactor)

  let width = singleBoxValues[2]
  let height = singleBoxValues[3]

  svg.setAttribute('width', width)
  svg.setAttribute('height', height)

  if (!originStyle) return

  let styleObject = stringToObject(originStyle)

  let chartContainerDimensions = getChartContainerDimensions()

  for (const key in styleObject) {
    if (Object.hasOwnProperty.call(styleObject, key)) {
      const value = styleStringToFloat(styleObject[key])
      let newValue = value * zoomFactor
      if (key === 'left') {
        let leftOffset = 0
        let chartContainerLeftOffset =
          chartContainerDimensions.marginLeft +
          chartContainerDimensions.paddingLeft
        if (chartContainerLeftOffset) {
          let total =
            chartContainerDimensions.width + 2 * chartContainerLeftOffset
          let newWidth = chartContainerDimensions.widthOrigin * zoomFactor
          leftOffset = (total - newWidth) / 2
        }

        newValue += leftOffset
      }
      svg.style.setProperty(key, newValue + 'px')
    }
  }
}

/**
 * Aktualisiert die absolute Position eines <svg> Elements
 * @function repositionSVG
 * @param {object} svg - SVG Element
 * @param {number} zoomFactor - Zoom Faktor
 */
export const repositionSVG = (svg, zoomFactor) => {
  const originStyle = svg.getAttribute('style-origin')

  if (!originStyle) return

  let styleObject = stringToObject(originStyle)

  let chartContainerDimensions = getChartContainerDimensions()

  for (const key in styleObject) {
    if (Object.hasOwnProperty.call(styleObject, key)) {
      const value = styleStringToFloat(styleObject[key])
      let newValue = value * zoomFactor
      if (chartContainerDimensions && key === 'left') {
        let chartContainerLeftOffset =
          chartContainerDimensions.marginLeft +
          chartContainerDimensions.paddingLeft

        newValue += chartContainerLeftOffset
      }
      svg.style.setProperty(key, newValue + 'px')
    }
  }
}

/**
 * Erzeugt aus einem objekt einen string
 * @function objectToString
 * @param {object} object - SVG Element
 */
const objectToString = (object) => {
  let styleProperties = []
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const styleProperty = object[key]
      styleProperties.push(`${key}:${styleProperty}`)
    }
  }
  let resultString = styleProperties.join(';')

  return resultString
}

/**
 * Erzeugt aus einem style string ein objekt
 * @function stringToObject
 * @param {string} string - SVG Element
 */
const stringToObject = (string) => {
  let resultObject = {}
  let styleProperties = string.split(';')

  styleProperties.forEach((style) => {
    let keyValuePair = style.split(':')
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    resultObject[key] = value
  })

  return resultObject
}

/**
 * Erzeugt aus einem style string ein objekt
 * @function styleStringToFloat
 * @param {string} string - SVG Element
 */
const styleStringToFloat = (string) => {
  return parseFloat(string.replace(/px/, ''))
}

/**
 * Gibt die relevanten Dimensionen des chartContainer Elements zurück
 * @function updateChartContainerDimensions
 */
export const updateChartContainerDimensions = () => {
  const chartContainerDiv = document.getElementById('chartContainer')
  let chartContainerStyle =
    chartContainerDiv.currentStyle || window.getComputedStyle(chartContainerDiv)

  chartContainerDiv.setAttribute('width-origin', chartContainerStyle.width)
}

/**
 * Gibt die relevanten Dimensionen des chartContainer Elements zurück
 * @function getChartContainerDimensions
 * @returns {object} chartContainerDimensions
 */
export const getChartContainerDimensions = () => {
  const chartContainerDiv = document.getElementById('chartContainer')
  const chartContainerDivParent = document.getElementById(
    'chartContainerParent'
  )

  let chartContainerParentStyle = chartContainerDivParent
    ? window.getComputedStyle(chartContainerDivParent)
    : null

  let dimensions = {}
  // dimensions.widthOrigin = styleStringToFloat(
  //   chartContainerDiv.getAttribute('width-origin')
  // )
  // dimensions.width = styleStringToFloat(chartContainerStyle.width)
  // dimensions.height = styleStringToFloat(chartContainerStyle.height)
  // dimensions.marginLeft = styleStringToFloat(chartContainerStyle.marginLeft)
  // dimensions.paddingLeft = styleStringToFloat(chartContainerStyle.paddingLeft)
  dimensions.box = chartContainerDiv
    ? chartContainerDiv.getBoundingClientRect()
    : null
  dimensions.parentLeft = chartContainerParentStyle
    ? styleStringToFloat(chartContainerParentStyle.marginLeft)
    : 0
  return dimensions
}
