import { BAR_CONST, ID } from '../constants'
import { makeId, setHoverEffect } from '../helper'
import { useChartStore } from '../../stores/chart'
import { createSVG, updateSVG } from '../general/svgElement'
import { SVG } from '@svgdotjs/svg.js'
import { drawSquare } from '../general/rect'

/**
 * @function drawBarLegend
 * @param {import('../typedef').Antwort[]} antworten - Antworten Array
 * @returns {object} - svgObject
 */
export function drawBarLegend(antworten) {
  const chartStore = useChartStore()
  const answers = antworten.sort((a, b) => a.reihenfolge - b.reihenfolge)
  const svgContainer = createSVG(ID.bar.legend)
  const rectWidth = 10
  let legendElement = svgContainer.group()
  legendElement.id(ID.barLegend)

  var xPos = BAR_CONST.chartAreaX
  var top = 0

  for (let index = 0; index < answers.length; index++) {
    const antwort = answers[index]
    const farbe = chartStore.getFarbeById(antwort.farbe_id)
    if (antwort.reihenfolge === 0) continue
    let group = svgContainer.group()
    group.addClass(ID.bar.legend)
    group.id(makeId([ID.bar.legend, antwort.id]))
    // group.attr('defaultGroup', d.defaultGroup)

    const textElement = drawLegendText(
      svgContainer,
      antwort.antwort,
      xPos + BAR_CONST.legendRectSize + BAR_CONST.legendRectTextOffset,
      top
    )
    // if (d.defaultGroup) textElement.attr('font-weight', 'bold')
    const textElementBox = textElement.bbox()
    const textElementCenter = textElementBox.h / 2

    const rectElement = drawLegendRect(
      farbe.farbe,
      xPos,
      top + textElementCenter
    )
    group.add(rectElement)

    group.add(textElement)

    group.on('mouseenter', () => {
      highlightBars(antwort.id)
    })
    group.on('mouseleave', () => {
      dehighlightBars(antwort.id)
    })

    if (textElementBox.x2 > BAR_CONST.totalWidth) {
      xPos = BAR_CONST.chartAreaX
      top += textElementBox.h * 1.5
      group.move(xPos, top)
      xPos += rectWidth + textElementBox.w + BAR_CONST.legendElementOffset
    } else {
      xPos += rectWidth + textElementBox.w + BAR_CONST.legendElementOffset
    }
    legendElement.add(group)
  }

  let box = legendElement.bbox()
  updateSVG(svgContainer, BAR_CONST.totalWidth, box.h)
  return legendElement
}

/**
 * @function drawLegendText
 * @param {object} container - Der SVG() Container
 * @param {string} text - Text in Pfeil
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @returns {object} - bar-svgObject
 */
function drawLegendText(container, text, xPos, yPos) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.legendFontSize
  const anchor = BAR_CONST.legendAnchor
  const baseline = BAR_CONST.legendBaseline

  const nameArray = [text]

  const sideInfoHeaderTextElement = svg
    .text(function (add) {
      nameArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .move(xPos, yPos)

  return sideInfoHeaderTextElement
}

/**
 * @function drawLegendRect
 * @param {string} color - Farbe des Quadrats
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @returns {object} - svgObject
 */
function drawLegendRect(color, xPos, yPos) {
  const size = BAR_CONST.legendRectSize
  const stroke = BAR_CONST.legendRectStroke

  return drawSquare(xPos, yPos, size, color, stroke)
}

export const highlightBars = (answerId) => {
  const bars = document.getElementsByClassName(ID.bar.itemOverlay)
  Array.prototype.forEach.call(bars, (bar) => {
    let svgBar = SVG(bar)
    let barItemAnswerId = JSON.parse(svgBar.attr('IDs')).antwort_id
    if (barItemAnswerId !== answerId) setHoverEffect(svgBar)
  })
  const barLegend = SVG('#' + ID.bar.legend + ID.separator + answerId)
  barLegend.attr('font-weight', 'bold')
}
export const dehighlightBars = () => {
  const bars = document.getElementsByClassName(ID.bar.itemOverlay)
  Array.prototype.forEach.call(bars, (bar) => {
    let svgBar = SVG(bar)
    // svgBar.fill('rgba(255,255,255,0)')
    setHoverEffect(svgBar, true)
  })
  const barLegends = document.getElementsByClassName(ID.bar.legend)
  Array.prototype.forEach.call(barLegends, (barLegend) => {
    let svgLegend = SVG(barLegend)
    svgLegend.attr('font-weight', 'normal')
  })
}
