import { useChartStore } from '../stores/chart'

import { drawLineChart } from './line/drawLineChart'
import { drawBarChart } from './bar/drawBarChart'
import { drawPieChart } from './pie/drawPieChart'

export const draw = () => {
  const chartStore = useChartStore()
  const frage = chartStore.selectedQuestion
  switch (frage?.typ.toLowerCase()) {
    case 'linien':
      drawLineChart(frage)
      break
    case 'balken':
      drawBarChart(frage)
      break
    case 'kuchen':
      drawPieChart(frage)
      break
    default:
      break
  }
}
