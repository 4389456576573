//@ts-check
import { LINE_CONST, ID, BAR_CONST } from '../constants'
import { makeId } from '../helper'
import {
  updateLineView,
  resetLineView,
  setLineView
  // highlightLine,
  // deHighlightLine
} from './lineHelper'
import { useChartStore } from '../../stores/chart'
import { createSVG, updateSVG } from '../general/svgElement'

/**
 * @function drawLineLegend
 * @param {import('../typedef').Gruppenwert[]} gruppenWerte - GruppenWerte einer Unterfrage
 * @param {import('../typedef').ID} IDs - IDs Objekt
 * @returns {object} - svgObject
 */
export function drawLineLegend(gruppenWerte, IDs) {
  const chartStore = useChartStore()

  let svgContainer = createSVG(ID.line.legend)

  const rowHeight = 30
  const rectWidth = 10
  let legendElement = svgContainer.group()
  // legendElement.id(ID.line.legend)

  var xPos = LINE_CONST.chartAreaX
  const xPosCategory = xPos - 20

  var top = rowHeight / 2 //LINE_CONST.legendAreaOffset

  let lastCategory = null
  for (let index = 0; index < gruppenWerte.length; index++) {
    const gruppenWert = gruppenWerte[index]

    if (!chartStore.getGruppeIsSelectedById(gruppenWert.gruppe_id)) {
      // Gruppe überspringen
      continue
    }
    const gruppe = chartStore.getGruppeByGruppenWert(gruppenWert)
    const kategorie = chartStore.getKategorieById(gruppenWert.kategorie_id)
    const farbe = chartStore.getFarbeById(gruppe.farbe_id).farbe

    const xPosTextOffset =
      BAR_CONST.legendRectSize + BAR_CONST.legendRectTextOffset

    let categoryElement = null
    if (kategorie.name !== lastCategory) {
      categoryElement = drawLegendCategoryText(
        svgContainer,
        kategorie.name,
        xPosCategory,
        top
      )
      legendElement.add(categoryElement)
    }
    let svgGroup = svgContainer.group()
    svgGroup.addClass(ID.line.legend)
    svgGroup.id(makeId([ID.line.legend, gruppe.id]))
    // svgGroup.attr('isStandardGruppe', gruppe.standard)
    svgGroup.attr('IDs', JSON.stringify({ ...IDs, gruppe_id: gruppe.id }))

    const textElement = drawLegendText(
      svgContainer,
      gruppe.name,
      xPos + xPosTextOffset,
      top
    )
    // if (group.isDefaultGroup) svgGroup.attr('font-weight', 'bold')
    const textElementBox = textElement.bbox()
    // const textElementCenter = textElementBox.h / 2

    const rectElement = drawLegendRect(
      svgContainer,
      farbe,
      xPos,
      top //+ textElementCenter
    )

    svgGroup.add(rectElement)

    svgGroup.add(textElement)

    svgGroup.on('mouseenter, mouseover', (e) => {
      updateLineView(e)
    })
    svgGroup.on('mouseleave', () => {
      resetLineView()
    })
    svgGroup.on('click', (e) => {
      setLineView(e)
    })

    if (
      textElementBox.x2 > LINE_CONST.totalWidth ||
      (lastCategory && categoryElement)
    ) {
      xPos = LINE_CONST.chartAreaX
      top += rowHeight
      rectElement.move(xPos, top - BAR_CONST.legendRectSize / 2)
      textElement.amove(xPos + xPosTextOffset, top)
      if (categoryElement) categoryElement.amove(xPosCategory, top)
      xPos += rectWidth + textElementBox.w + BAR_CONST.legendElementOffset
    } else {
      xPos += rectWidth + textElementBox.w + BAR_CONST.legendElementOffset
    }
    legendElement.add(svgGroup)
    lastCategory = kategorie.name
  }
  updateSVG(
    svgContainer,
    LINE_CONST.totalWidth,
    legendElement.bbox().h + rowHeight
  )

  return legendElement
}

/**
 * @function drawLegendText
 * @param {object} container - Der SVG() Container
 * @param {string|Array<string>} text - Text in Pfeil
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @returns {object} - bar-svgObject
 */
function drawLegendText(container, text, xPos, yPos) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.legendFontSize
  const anchor = BAR_CONST.legendAnchor
  const baseline = 'central' //BAR_CONST.legendBaseline

  const nameArray = Array.isArray(text) ? text : [text]

  const legendTextElement = svg
    .text(function (add) {
      nameArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(xPos, yPos)

  return legendTextElement
}

/**
 * @function drawLegendRect
 * @param {object} container - Der SVG() Container
 * @param {string} color - Farbe des Quadrats
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @returns {object} - bar-svgObject
 */
function drawLegendRect(container, color, xPos, yPos) {
  const svg = container //store.state.SVG_CONTAINER
  const size = BAR_CONST.legendRectSize
  const stroke = BAR_CONST.legendRectStroke
  const rectElement = svg
    .rect(size, size)
    .fill(color)
    .stroke(stroke)
    .move(xPos, yPos - size / 2)

  return rectElement
}

/**
 * @function drawLegendCategoryText
 * @param {object} container - Der SVG() Container
 * @param {string|Array<string>} text - Text in Pfeil
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @returns {object} - bar-svgObject
 */
function drawLegendCategoryText(container, text, xPos, yPos) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.legendFontSize
  const anchor = 'end'
  const baseline = 'central' //BAR_CONST.legendBaseline

  const nameArray = Array.isArray(text) ? text : [text]

  const sideInfoHeaderTextElement = svg
    .text(function (add) {
      nameArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(xPos, yPos)

  return sideInfoHeaderTextElement
}
