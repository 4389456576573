import { drawLineSubquestionLabel } from './lineSubquestionLabel'
import { drawLineBenchmark } from './lineBenchmark'
import { drawLineBackground } from './lineBackground'
/**
 * @function drawLineBenchmarkAndLabel
 * @param {import('../typedef').Unterfrage} unterfrage - Unterfrage
 * @param {import('../typedef').ID} IDs - IDs
 * @param {number} yPos - Y-Position der Zeile
 */
export function drawLineBenchmarkAndLabel(unterfrage, yPos, IDs) {
  const subquestionId = unterfrage.id
  const label = unterfrage.svgFrage

  const rowElement = drawLineBackground(subquestionId, yPos)

  const labelElement = drawLineSubquestionLabel(label, subquestionId, yPos)
  //Erste Zeile vom text finden für Benchmark-Quadrat
  const firstTextLine = labelElement.children()[0]
  const firstTextLineCenter =
    firstTextLine.bbox().y + firstTextLine.bbox().h / 2

  let benchmarkElement = drawLineBenchmark(firstTextLineCenter, IDs)
  return { rowElement, labelElement, benchmarkElement }
}
