<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-card>
          <v-card-title> Admin </v-card-title>
          <v-card-text>
            <v-list>
              <User v-for="(user, index) in users" :key="index" :user="user" />
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="dialog = true" color="primary">Neuer Nutzer</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Anleitung -->
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-card>
          <v-card-title> Anleitung </v-card-title>
          <v-card-text>
            Um die Daten zu den jeweiligen Nutzern hochzuladen befolgen Sie
            folgende Schritte:
            <ol>
              <li>Melden Sie sich in der Konsole des Hosting Anbieters an.</li>
              <li>
                Wechseln Sie zum Reiter "Produkte", dann links unter
                "Einstellungen" -> "Extras" -> "WebFTP" und dann den Button
                "WebFTP öffnen" klicken
              </li>
              <li>Dort navigieren Sie zu public_html/private/Daten</li>
              <li>
                Dort muss ein Ordner existieren, der <b>EXAKT</b> so heißt, wie
                der Nutzer
              </li>
              In diesen Ordner kann das JSON per "Dateien hinzufügen"
              hochgeladen werden
            </ol>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Neuer Nutzer </v-card-title>

        <v-form
          ref="refForm"
          v-model="valid"
          lazy-validation
          @submit="registerUser"
        >
          <v-card-text>
            <v-text-field
              v-model="username"
              :counter="20"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Passwort"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="close()">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :disabled="!valid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import User from '../components/User'
import { useComStore } from '../stores/communication'

export default {
  name: 'AdminComponent',
  components: {
    User
  },
  setup() {
    const comStore = useComStore()
    const { users } = storeToRefs(comStore)
    const refForm = ref(null)
    const dialog = ref(false)
    const valid = ref(false)

    const username = ref('')
    const password = ref('')
    function registerUser() {
      if (refForm.value.validate()) {
        var data = { username: username.value, password: password.value }
        comStore.registerUser(data)
        close()
      }
    }
    function close() {
      username.value = ''
      password.value = ''
      dialog.value = false
    }

    onMounted(() => {
      comStore.getUsers()
    })

    return {
      users,
      valid,
      dialog,
      refForm,
      username,
      password,
      close,
      registerUser,
      nameRules: [
        (v) => !!v || 'Name darf nicht leer sein',
        (v) =>
          (v && v.length <= 20) || 'Name darf nicht länger als 20 Zeichen sein'
      ],
      passwordRules: [
        (v) => !!v || 'Passwort darf nicht leer sein',
        (v) =>
          (v && v.length <= 20) ||
          'Passwort darf nicht länger als 20 Zeichen sein'
      ]
    }
  }
}
</script>

<style scoped></style>
