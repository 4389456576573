var FileSaver = require('file-saver')
import domtoimage from 'dom-to-image'
import { elementToSVG, inlineResources } from 'dom-to-svg'

function save(dataBlob, filename) {
  FileSaver.saveAs(dataBlob, filename) // FileSaver.js function
}

export default async function svgToImage(dom, filename, filetype) {
  if (filetype === 'png') {
    let scale = 1
    return domtoimage
      .toPng(dom, {
        quality: 1,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      })
      .then((dataUrl) => {
        save(dataUrl, filename)
      })
  }
  // Capture specific element
  const svgDocument = elementToSVG(dom)

  // Inline external resources (fonts, images, etc) as data: URIs
  await inlineResources(svgDocument.documentElement)
  // url("https://fonts.googleapis.com/css?family=Roboto:400")
  // fix font
  svgDocument.childNodes[0].childNodes.forEach((node) => {
    if (node.nodeName === 'style') {
      node.textContent = `@font-face { font-family: Roboto; font-style: normal; font-weight: 400; src: url("https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2") format('woff2')}  svg{
        font-family: Roboto, sans-serif;
    }`
    }
  })
  // Get SVG string
  var svgString = new XMLSerializer().serializeToString(svgDocument)

  //add name spaces.
  if (!svgString.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
    svgString = svgString.replace(
      /^<svg/,
      '<svg xmlns="http://www.w3.org/2000/svg"'
    )
  }
  if (!svgString.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
    svgString = svgString.replace(
      /^<svg/,
      '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
    )
  }
  //remove error producing svgjs:data attributes, svgjs:data="{&quot;leading&quot;:&quot;1.3&quot;}"
  if (svgString.match(/svgjs:data/g)) {
    svgString = svgString.replace(/svgjs:data/g, 'data')
  }

  //add xml declaration
  svgString = '<?xml version="1.0" standalone="no"?>\r\n' + svgString

  //convert svg source to URI data scheme.
  var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString)
  return save(url, filename)
}

function download_files(files) {
  function download_next(i) {
    if (i >= files.length) {
      return
    }
    var a = document.createElement('a')
    a.href = files[i].download
    a.target = '_parent'
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = files[i].filename
    }
    // Add a to the doc for click to work.
    ;(document.body || document.documentElement).appendChild(a)
    if (a.click) {
      a.click() // The click method is supported by most browsers.
    } else {
      $(a).click() // Backup using jquery
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a)
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1)
    }, 500)
  }
  // Initiate the first download.
  download_next(0)
}
