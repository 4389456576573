//@ts-check
import { LINE_CONST, COLORS_CONST, ID } from '../constants'
import * as TOOLTIP from '../svgTooltip'
import { makeId } from '../helper'
import { useChartStore } from '../../stores/chart'

/**
 * @function drawLineBenchmark
 * @param {number} yPosCenter - Y-Koordinate der Mitte des Quadrates
 * @param {import('../typedef').ID} IDs - IDs
 * @returns {object} - bar-svgObject
 */
export function drawLineBenchmark(yPosCenter, IDs) {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer
  const bmSize = LINE_CONST.benchmarkSize
  const bmX = LINE_CONST.benchmarkX
  const bmColor = COLORS_CONST.white

  let benchmarkElement = svgContainer
    .rect(bmSize, bmSize)
    .fill(bmColor)
    .opacity(0)
    .move(bmX, yPosCenter - bmSize / 2)
    .addClass(ID.benchmark)
    .id(makeId([ID.benchmark, IDs.unterfrage_id]))
    .attr('IDs', JSON.stringify(IDs))
  benchmarkElement.on('mouseenter', TOOLTIP.init)
  benchmarkElement.on('mouseleave', TOOLTIP.hide)

  return benchmarkElement
}
