const DEV = process.env.NODE_ENV === 'development'

const URL = DEV
  ? 'http://localhost:3000/public_html/communication.php'
  : 'https://bmq-portal.com/communication.php'

export function createHeader() {
  const token = localStorage.getItem('jwt_token')
  const headers = {}
  headers['Content-Type'] = 'application/json' //JSON
  headers.Accept = 'application/json' // expected data sent back
  if (token) {
    headers.Authorization = `Bearer ${token}` //JWT Token
  }
  return headers
}

/**
 *
 * @param {Response} res
 * @returns
 */
export function checkResponse(res) {
  if (res.status === 401) {
    throw new Error('Sie sind nicht autorisiert.')
  }
  return res.text().then((text) => {
    var result = {}
    try {
      result = JSON.parse(text)
    } catch (err) {
      console.error(err, text)
      throw new Error('Fehler in Serverantwort')
    }
    if (result.error) throw new Error(result.error)
    return result
  })
}

export function fetchFromServer(body) {
  return fetch(URL, {
    method: 'post',
    headers: createHeader(),
    body: JSON.stringify(body)
  })
    .catch((error) => {
      console.error(error)
      throw new Error('Server ist nicht erreichbar')
    })
    .then((res) => checkResponse(res))
}

export const checkAuth = () => {
  var json = { Controller: 'User', Action: 'checkauth' }
  return fetchFromServer(json).catch((error) => Promise.reject(error))
}

const directFilePath = './data.json'

export function getOfflineData() {
  let jsonResponse = null
  let jsonFile = null
  // try {
  //   jsonFile = require('./data.json')
  // } catch (error) {
  //   console.warn(error)
  //   try {
  //     jsonFile = require('../../public_html/private/Daten/Musterkunde/data.json')
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }
  jsonResponse = jsonFile ? jsonFile : requestJSON()
  if (!jsonResponse) return Promise.reject(false)
  let json = {
    data: jsonResponse?.data ?? jsonResponse,
    welcome:
      '<h3>OFFLINE MODUS</h3> <p>In diesem Modus ist nur der Viewer verfügbar, jedoch müssen die Daten direkt serviert werden, da keine Verbindung zum Server hergestellt werden kann. Dazu muss das gesamte JSON in der Abfrage eingegeben werden.</p> <p>Außerdem kann hier der Sammeldownload durchgeführt werden. Dazu sollte im Browser ein fester Download Ordner eingestellt werden, um nicht bei jeder Datei gefragt zu werden.</p> '
  }
  return Promise.resolve(json)
}

function requestJSON() {
  let json = prompt(`Sie sind offline. Bitte gesamtes JSON hier einfügen`)
  if (!json) {
    return false
  }
  return tryParseJSONObject(json)
}

function tryParseJSONObject(jsonString) {
  if (jsonString && typeof jsonString === 'object') {
    return jsonString
  }
  try {
    var o = JSON.parse(jsonString)

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}

  return false
}
