import { defineStore } from 'pinia'
import { fetchFromServer, getOfflineData } from '../plugins/com'

export const useComStore = defineStore('com', {
  state: () => ({
    sourceJson: null,
    files: null,
    user: null,
    users: [],
    offlineUser: { id: 1, name: 'OFFLINE', isAdmin: false },
    error: null
  }),
  getters: {
    offline() {
      return (
        window.location.href.includes('file://') ||
        window.location.href.includes('offline')
      )
    },
    welcome() {
      return this.sourceJson?.welcome
    },
    dataReceived() {
      return (
        (!!this.offline && !!this.sourceJson) ||
        (!!this.sourceJson && !!this.folders)
      )
    },
    folders() {
      return this.files ? Object.keys(this.files) : null
    }
  },
  actions: {
    login(params) {
      const body = { Controller: 'User', Action: 'LOGIN', Params: params }
      return fetchFromServer(body)
        .then((result) => {
          if (result.token && result.userData) {
            localStorage.setItem('jwt_token', result.token)
            return result
          } else {
            throw new Error(
              'Die Antwort vom Server hat ein unerwartetes Format.'
            )
          }
        })
        .then((user) => {
          this.user = user
          if (user.isAdmin) {
            return { name: 'Admin' }
          } else {
            return { name: 'Welcome' }
          }
        })
        .catch((error) => {
          this.error = error
          return false
        })
    },
    checkAuth() {
      if (this.offline) {
        this.user = this.offlineUser
        return Promise.resolve(this.offlineUser)
      }
      var body = { Controller: 'User', Action: 'checkauth' }

      return fetchFromServer(body).then((result) => {
        const user = {
          id: result.payload.userId,
          name: result.payload.username,
          isAdmin: result.payload.isAdmin
        }
        this.user = user
        return user
      })
    },
    getData() {
      if (this.offline) {
        return getOfflineData()
          .then((result) => (this.sourceJson = result))
          .catch((err) => console.error('Kein JSON'))
      }
      var body = { Controller: 'Data', Action: 'get_json_file' }

      return fetchFromServer(body)
        .then((result) => (this.sourceJson = result))
        .catch((error) => (this.error = error))
    },
    getFiles() {
      if (this.offline) {
        return Promise.resolve([])
      }
      var body = {
        Controller: 'Data',
        Action: 'get_all_files',
        Params: { username: this.user.name }
      }

      return fetchFromServer(body).then((response) => {
        if (response.folders) {
          this.files = response.folders
        } else {
          this.files = []
          console.info(response.info)
        }
      })
    },
    logout() {
      localStorage.clear()
      this.$reset()
    },
    // admin
    getUsers() {
      var body = {
        Controller: 'User',
        Action: 'get_users'
      }

      return fetchFromServer(body).then(
        (response) =>
          (this.users = response.users.sort((a, b) => b.isAdmin - a.isAdmin))
      )
    },
    editUser(data) {
      var body = {
        Controller: 'User',
        Action: 'edit_user',
        Params: data
      }

      return fetchFromServer(body)
    },
    registerUser(data) {
      var body = {
        Controller: 'User',
        Action: 'register_user',
        Params: data
      }

      return fetchFromServer(body).then(() => this.getUsers())
    },
    deleteUser(data) {
      console.log('🚀 ~ file: communication.js:136 ~ deleteUser ~ data:', data)
      var body = {
        Controller: 'User',
        Action: 'delete_user',
        Params: data
      }

      return fetchFromServer(body).then(() => this.getUsers())
    }
  }
})
