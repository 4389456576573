//@ts-check
import { LINE_CONST, COLORS_CONST } from '../constants'
// import { createSVG, updateSVG } from './svgElement'
// import { xAxis } from './xAxis'
// import { SVG } from '@svgdotjs/svg.js'

export const yAxis = {
  stroke: { width: 3, color: COLORS_CONST.axis }
}

/**
 * @function drawYAxis
 * @param {object} svgContainer - Höhe
 * @param {number} height - Höhe
 */
export function drawYAxis(svgContainer, height) {
  // const svgContainer = createSVG(null, 'svg-axis')

  const xPos = LINE_CONST.chartAreaX
  const yPos = 0 //LINE_CONST.chartAreaY
  const yPosEnd = height //+ xAxis.tickHeight + xAxis.stroke.width / 2
  return svgContainer.line(xPos, yPos, xPos, yPosEnd).stroke(yAxis.stroke)

  // updateSVG(svgContainer, yAxis.stroke.width, yPosEnd, {
  //   left: LINE_CONST.chartAreaX, //- yAxis.stroke.width / 2 + 'px',
  //   top: '0px'
  // })
}
