import { drawBarSubquestionLabel } from './barSubquestionLabel'
import { drawBarGroupName } from './barGroupName'
import { drawBarBenchmark } from './barBenchmark'

/**
 * @function drawBarBenchmarkAndText
 * @param {object} container - Der SVG() Container
 * @param {import('../typedef').Unterfrage | null} unterfrage - Text
 * @param {import('../typedef').Gruppenwert | null} gruppenWert - Text
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Höhe der Zeile
 * @returns {object} - bar-svgObject
 */
export function drawBarBenchmarkAndText(
  container,
  unterfrage,
  gruppenWert,
  yPos,
  rowHeight
) {
  // const svg = container //store.state.SVG_CONTAINER
  var textElement = null
  let benchmarkAndTextElement = container.group()
  if (unterfrage) {
    textElement = drawBarSubquestionLabel(
      container,
      unterfrage,
      yPos,
      rowHeight
    )
  } else {
    textElement = drawBarGroupName(container, gruppenWert, yPos, rowHeight)
  }

  if (gruppenWert.benchmark_id) {
    //Erste Zeile vom text finden für Benchmark-Quadrat
    const firstTextLine = textElement.children()[0]
    const firstTextLineCenter =
      firstTextLine.bbox().y + firstTextLine.bbox().h / 2

    const benchmarkElement = drawBarBenchmark(
      container,
      gruppenWert,
      firstTextLineCenter
    )
    benchmarkAndTextElement.add(benchmarkElement)
  }
  benchmarkAndTextElement.add(textElement)
  return benchmarkAndTextElement
}
