//@ts-check
import { createSVG, updateSVG } from './svgElement'

/**
 * @function drawScrollHelper
 */
export function drawScrollHelper() {
  const svgContainer = createSVG('scroll-helper', 'scroll-helper')
  const height = document.getElementById('fullChartContainer').clientHeight / 2

  updateSVG(svgContainer, 0, height)
}
