import Vue from 'vue'
import VueRouter from 'vue-router'
//pages
import Login from '../pages/Login.vue'
import Welcome from '../pages/Welcome.vue'
import Admin from '../pages/Admin.vue'
import Unknown from '../pages/Unknown.vue'
import Chart from '../pages/Chart.vue'
import Reports from '../pages/Reports.vue'

//com
import { useComStore } from '../stores/communication'

Vue.use(VueRouter)

function authHelper(cb, to) {
  const comStore = useComStore()
  comStore
    .checkAuth()
    .then((user) => {
      if (user?.isAdmin && to.name !== 'Admin') cb({ name: 'Admin' })
      if (user && to.name === 'Login') cb({ name: 'Welcome' })
      cb()
    })
    .catch((error) => {
      if (to.name !== 'Login') {
        cb({ name: 'Login' })
        comStore.error = error.message ?? error
      }

      cb()
    })
}

const routes = [
  {
    name: 'Login',
    path: '/',
    component: Login,
    beforeEnter: (to, from, next) => {
      authHelper(next, to)
    }
  },
  {
    name: 'Admin',
    path: '/admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      authHelper(next, to)
    }
  },
  {
    name: 'Welcome',
    path: '/welcome',
    component: Welcome,
    beforeEnter: (to, from, next) => {
      authHelper(next, to)
    }
  },
  {
    name: 'Viewer',
    path: '/viewer',
    component: Chart,
    beforeEnter: (to, from, next) => {
      authHelper(next, to)
    }
  },
  {
    name: 'Reports',
    path: '/reports/:folder',
    component: Reports,
    beforeEnter: (to, from, next) => {
      authHelper(next, to)
    }
  },
  { name: 'Unknown', path: '/*', component: Unknown }
]

const router = new VueRouter({
  routes
})

export default router
