<template>
  <v-container fluid class="px-sm-8 px-md-16">
    <v-row v-if="!dataReceived" justify="center">
      <!-- Loaders -->
      <v-col cols="auto text-center">
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p v-if="!dataFetched">Daten werden geladen...</p>
          <p v-else>Es scheint ein Problem mit der Verbindung zu geben...</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="12" md="8" order="12" order-md="1">
        <v-row no-gutters justify="space-between">
          <!-- BMQ-Viewer -->
          <v-col cols="12" sm="5" md="4" class="mb-6">
            <FolderButton name="BMQ-Viewer" to="Viewer" />
          </v-col>
          <!-- FOLDERS -->
          <v-col
            cols="12"
            sm="5"
            md="4"
            v-for="folder in folders"
            :key="folder"
            class="mb-6"
          >
            <FolderButton :name="folder" :param="folder" to="Reports" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" order="1" order-md="12">
        <v-card flat class="welcome-text">
          <div class="pa-1 mx-2 text-left" v-html="welcomeText"></div>
        </v-card>
      </v-col>
    </v-row>
    <div class="page-filler"></div>
  </v-container>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import FolderButton from '../components/FolderButton'
import { useComStore } from '../stores/communication'
import { useChartStore } from '../stores/chart'

export default {
  name: 'WelcomePage',
  components: {
    FolderButton
  },
  props: {},
  setup() {
    const jsonFetched = ref(false)
    const foldersFetched = ref(false)
    const comStore = useComStore()
    const { welcome, dataReceived, folders, files } = storeToRefs(comStore)

    onMounted(() => {
      fetchData()
    })

    const chartStore = useChartStore()
    function fetchData() {
      if (!files.value) {
        comStore.getFiles().then((result) => {
          foldersFetched.value = true
        })
      }
      if (!comStore.sourceJson) {
        comStore.getData().then((result) => {
          jsonFetched.value = true
          chartStore.initStoreWithJson()
        })
      }
    }

    const dataFetched = computed(
      () => foldersFetched.value && jsonFetched.value
    )

    return {
      dataFetched,
      dataReceived,
      folders,
      welcomeText: welcome
    }
  }
}
</script>

<style></style>
