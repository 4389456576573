//@ts-check
import { BAR_CONST, ID } from '../constants'
import { makeId } from '../helper'

/**
 * @function drawBarSubquestionLabel
 * @param {object} container - Der SVG() Container
 * @param {import('../typedef').Unterfrage} unterfrage - Unterfragen-Label in Array
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Höhe der Zeile
 * @returns {object} - bar-svgObject
 */
export function drawBarSubquestionLabel(
  container,
  unterfrage,
  yPos,
  rowHeight
) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.subquestionLabelFontSize
  const anchor = BAR_CONST.subquestionLabelAnchor
  const baseline = BAR_CONST.subquestionLabelBaseline

  const xPos = BAR_CONST.subquestionLabelX

  const subquestionLabelElement = svg
    .text(function (add) {
      unterfrage.svgFrage?.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .addClass(ID.subquestionLabel)
    .id(makeId([ID.subquestionLabel, unterfrage.id]))

  const textBoxHeight = subquestionLabelElement.bbox().h
  const targetOffset = (rowHeight - textBoxHeight) / 2
  subquestionLabelElement.move(xPos, yPos + targetOffset)
  return subquestionLabelElement
}
