import { BAR_CONST, BMQ_COLORS, COLORS_CONST, ID } from '../constants'
import * as TOOLTIP from '../svgTooltip'
import { useChartStore } from '../../stores/chart'

/**
 * @function drawBarBenchmark
 * @param {object} container - Der SVG() Container
 * @param {object} group - Gruppen-Objekt
 * @param {number} yPosCenter - Y-Koordinate der Mitte des Quadrates
 * @returns {object} - bar-svgObject
 */
export function drawBarBenchmark(container, group, yPosCenter) {
  const chartStore = useChartStore()

  const svg = container //store.state.SVG_CONTAINER
  const isDefaultGroup = chartStore.defaultGroupId
  const bmSize = isDefaultGroup
    ? BAR_CONST.benchmarkLabelSize
    : BAR_CONST.benchmarkGroupNameSize
  const bmX = isDefaultGroup
    ? BAR_CONST.benchmarkLabelX
    : BAR_CONST.benchmarkGroupNameX
  const benchmark = chartStore.getBenchmarkById(group.benchmark_id)

  let benchmarkElement = svg
    .rect(bmSize, bmSize)
    .fill(
      benchmark
        ? chartStore.getFarbeById(benchmark.farbe_id).farbe
        : COLORS_CONST.white
    )
    .attr('fill-opacity', benchmark ? 1 : 0)
    .move(bmX, yPosCenter - bmSize / 2)
  benchmarkElement.on('mouseenter', TOOLTIP.init)
  benchmarkElement.on('mouseleave', TOOLTIP.hide)
  benchmarkElement.addClass(ID.benchmark)
  return benchmarkElement
}
