import { BAR_CONST, COLORS_CONST, ID, OFFSET } from '../constants'
import { useChartStore } from '../../stores/chart'
import { drawSideInfoHeader } from './sideInfoHeader'
import { createSVG, updateSVG } from '../general/svgElement'

/**
 * @function arrowHeader
 * @param {number} questionHeight - Höhe des Fragen Objektes
 * @param {object} pfeile - Pfeile Objekt
 * @returns {number} - Höhe des SVG Objekts
 */
export function drawArrowHeader(questionHeight, pfeile) {
  const chartStore = useChartStore()

  // populate pfeile with farbe object
  const populatedPfeile = {
    ...pfeile,
    links: {
      ...pfeile.links,
      farbe: chartStore.getFarbeById(pfeile.links.farbe_id)
    },
    rechts: {
      ...pfeile.rechts,
      farbe: chartStore.getFarbeById(pfeile.rechts.farbe_id)
    }
  }
  //* SVG-Container erstellen
  let svgContainer = createSVG(ID.bar.arrowContainer, ID.bar.arrowContainer)

  const width = BAR_CONST.arrowAreaWidth / 2
  const barHeight = BAR_CONST.arrowBarHeight
  const arrowWidth = BAR_CONST.arrowTipWidth
  const arrowHeight = BAR_CONST.arrowTipHeight
  const xCenter = BAR_CONST.yAxisOverlapX

  let leftArrowPolygon = `0,${
    arrowHeight / 2
  } ${arrowWidth},0 ${arrowWidth},${arrowHeight} 0,${arrowHeight / 2} `

  let rightArrowPolygon = `0,${arrowHeight} ${arrowWidth},${
    arrowHeight / 2
  } 0,0  0,${arrowHeight} `

  let arrowElement = svgContainer.group()
  let background = svgContainer
    .rect(
      BAR_CONST.totalWidth,
      BAR_CONST.arrowAreaHeight + BAR_CONST.headerAreaY + OFFSET.arrow.bottom
    )
    .fill({ color: COLORS_CONST.barRowBackground })
    .move(0, 0)

  let leftArrow = svgContainer
    .polygon(leftArrowPolygon)
    .stroke({ width: 1, color: COLORS_CONST.border })
    .fill(populatedPfeile.links.farbe.farbe) //COLORS_CONST.arrowColorLeft)
    .move(xCenter - width, BAR_CONST.headerAreaY)

  let leftArrowBar = svgContainer
    .rect(width - arrowWidth, barHeight)
    .stroke({ width: 1, color: COLORS_CONST.border })
    .fill(populatedPfeile.links.farbe.farbe)
    .move(
      xCenter - width + arrowWidth,
      BAR_CONST.headerAreaY + (arrowHeight - barHeight) / 2
    )

  let rightArrow = svgContainer
    .polygon(rightArrowPolygon)
    .stroke({ width: 1, color: COLORS_CONST.border })
    .fill(populatedPfeile.rechts.farbe.farbe) //COLORS_CONST.arrowColorLeft)
    .move(xCenter + width - arrowWidth, BAR_CONST.headerAreaY)

  let rightArrowBar = svgContainer
    .rect(width - arrowWidth, barHeight)
    .stroke({ width: 1, color: COLORS_CONST.border })
    .fill(populatedPfeile.rechts.farbe.farbe)
    .move(xCenter, BAR_CONST.headerAreaY + (arrowHeight - barHeight) / 2)

  let blackLine = svgContainer
    .line(
      BAR_CONST.yAxisOverlapX,
      BAR_CONST.headerAreaY + (arrowHeight - barHeight) / 2,
      BAR_CONST.yAxisOverlapX,
      BAR_CONST.headerAreaY +
        BAR_CONST.arrowAreaHeight +
        1 +
        +OFFSET.arrow.bottom //+1, weil sonst weiße Lücke
    )
    .stroke(BAR_CONST.yAxisStroke)

  //Texte
  let leftTextElement = drawArrowText(
    svgContainer,
    pfeile.links.text || BAR_CONST.arrowTextLeft,
    true
  )
  let rightTextElement = drawArrowText(
    svgContainer,
    pfeile.rechts.text || BAR_CONST.arrowTextRight,
    false
  )

  let sideInfoHeader = drawSideInfoHeader(svgContainer)

  arrowElement.add(background)
  arrowElement.add(leftArrow)
  arrowElement.add(leftArrowBar)
  arrowElement.add(rightArrow)
  arrowElement.add(rightArrowBar)
  arrowElement.add(blackLine)
  arrowElement.add(leftTextElement)
  arrowElement.add(rightTextElement)
  arrowElement.add(sideInfoHeader)
  arrowElement.id(ID.bar.arrow)
  background.size(BAR_CONST.totalWidth, arrowElement.bbox().h)
  updateSVG(svgContainer, BAR_CONST.totalWidth, arrowElement.bbox().h, {
    top: questionHeight + 'px'
  })
  return arrowElement.bbox().h
}

/**
 * @function drawArrowText
 * @param {object} container - Der SVG() Container
 * @param {string} text - Text in Pfeil
 * @param {boolean} left - Text kommt in linken Pfeil
 * @returns {object} - bar-svgObject
 */
function drawArrowText(container, text, left = true) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.arrowTextFontSize
  const anchor = BAR_CONST.arrowTextAnchor
  const baseline = BAR_CONST.arrowTextBaseline

  const arrowBarCenter =
    (BAR_CONST.arrowAreaWidth / 2 - BAR_CONST.arrowTipWidth) / 2
  const xPos = left
    ? BAR_CONST.chartAreaXCenter - arrowBarCenter
    : BAR_CONST.chartAreaXCenter + arrowBarCenter

  const yPos = BAR_CONST.arrowTextY
  const nameArray = [text]

  const arrowTextElement = svg
    .text(function (add) {
      nameArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(xPos, yPos)

  return arrowTextElement
}
