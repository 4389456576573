/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
var _ = require('lodash')
import { SVG } from '@svgdotjs/svg.js'
import { useChartStore } from '../stores/chart'
import { useSettingsStore } from '../stores/settings'
import { ID } from './constants'
import { GENERAL_CONST, COLORS_CONST, BAR_CONST, BMQ_COLORS } from './constants'
import {
  createSVG,
  updateSVG,
  getChartContainerDimensions
} from './general/svgElement'

const mouseOffset = 25
const padding = 6
const rowHeight = 19
const rectSize = 10
const rectOffset = 5
const textOffset = 15
const col1X = padding
const row1Y = padding

const tooltipId = 'tooltip'
const tooltipClass = 'tooltipContainer'

let globalType = null
export const init = (e, params) => {
  const settingsStore = useSettingsStore()

  if (!settingsStore.svgTooltipsActive) return

  //init SVG Container
  let svgContainer = SVG('#' + tooltipId)
  if (!svgContainer) {
    svgContainer = createSVG(tooltipId, tooltipClass)
  }
  svgContainer.clear()
  const group = svgContainer.group()
  //detect what tooltip to show
  let type = detectType(Array.from(e.target.classList))
  //call correct function
  if (type === ID.bar.itemOverlay) {
    barItem(svgContainer, group, e)
  } else if (type === ID.line.line) {
    line(group, e, params)
  } else if (type === ID.line.point) {
    point(group, e, params)
  } else if (type === ID.benchmark) {
    benchmark(svgContainer, group, e)
  } else if (type === ID.n) {
    basic(group, e, 'Anzahl abgegebener Stimmen')
  } else if (type === ID.kA) {
    basic(group, e, 'Anteil nicht abgegebener Stimmen')
  } else {
    console.warn(`no tooltip type found for ${type}, target: `, e.target)
    hide()
  }
  globalType = type
}

const detectType = (classList) => {
  const types = [
    ID.bar.item,
    ID.bar.itemOverlay,
    ID.line.point,
    ID.line.line,
    ID.benchmark,
    ID.n,
    ID.kA
  ]
  let resultType = null
  for (let index = 0; index < types.length; index++) {
    const type = types[index]
    if (classList.indexOf(type) >= 0) {
      resultType = type
      break
    }
  }
  return resultType
}

export const hide = () => {
  const settingsStore = useSettingsStore()
  if (!settingsStore.svgTooltipsActive) return
  let typeToHide = globalType
  globalType = null
  setTimeout(() => {
    if (typeToHide != globalType) {
      let svgContainer = SVG('#' + tooltipId)
      svgContainer.removeClass('show')
      svgContainer.clear()
      updateSVG(svgContainer, 0, 0)
    }
  }, 500)
}

const showTooltip = (event, tooltipBox, target = null) => {
  let svgContainer = SVG('#' + tooltipId)
  const { x, y } = getCoordsForTooltip(event, tooltipBox, target)
  updateSVG(svgContainer, tooltipBox.w, tooltipBox.h, {
    left: x + 'px',
    top: y + 'px'
  })
  svgContainer.addClass('show')
}

const getCoordsForTooltip = (event, tooltipBox, forceTarget = null) => {
  let target = forceTarget || SVG(event.target)
  const screenPositionY = event.target.getBoundingClientRect().y

  const chartContainerDimensions = getChartContainerDimensions()
  const chartContainerBox = chartContainerDimensions.box
  const targetBox = target.bbox()
  const yPos = screenPositionY - chartContainerBox.y

  let x,
    y = 0
  //links von Objekt/Maus
  let left = targetBox.x - tooltipBox.w - mouseOffset > 0
  //Oberhalb von Objekt/Maus
  let top = yPos + tooltipBox.h > chartContainerDimensions.box.y2

  x = left
    ? targetBox.x - tooltipBox.w - mouseOffset
    : targetBox.x2 + mouseOffset
  x += chartContainerDimensions.parentLeft

  y = top ? yPos - tooltipBox.h : yPos

  return { x, y }
}

const drawBackground = () => {
  const svgContainer = SVG('#' + tooltipId)
  let bg = svgContainer
    .rect(0, 0)
    .radius(3)
    .stroke({ color: COLORS_CONST.tooltipBorder, width: 1 })
    .fill(COLORS_CONST.tooltipBackground)
    .move(0, 0)

  return bg
}

const basic = (group, e, tooltipText) => {
  let bg = drawBackground()
  group.add(bg)

  let col1 = drawTooltipText([tooltipText], col1X, row1Y, rowHeight)
  group.add(col1)
  const size = col1.bbox()
  bg.size(size.w + 2 * padding, size.h + 2 * padding)

  showTooltip(e, bg.bbox())
}

const barItem = (svgContainer, group, e) => {
  const allRects = SVG(e.target).siblings()
  const IDs = JSON.parse(SVG(e.target).attr('IDs'))
  const mostLeftRect = allRects.sort(
    (a, b) => a.node.x.baseVal.value - b.node.x.baseVal.value
  )[0]

  const target = mostLeftRect
  const col2X = col1X + rectSize + rectOffset // Text
  const dummyPosition = 0
  const chartStore = useChartStore()

  /**
   * @type {import('./typedef').Gruppenwert} gruppenWert
   */
  const gruppenWert = chartStore.getGruppenWertByIDs(IDs)
  const werte = gruppenWert.werte
  let bg = drawBackground(svgContainer)
  group.add(bg)

  var rowY = row1Y

  let n = drawTooltipText(['n'], 0, rowY, rowHeight, 'end')
  n.addClass('n')
  group.add(n)
  let percent = drawTooltipText(['%'], 0, rowY, rowHeight, 'end')
  percent.addClass('percent')
  group.add(percent)

  rowY += rowHeight
  var nTotal = gruppenWert.gesamtAntworten
  var kATotal = gruppenWert.gesamtKeineAngabe
  werte.forEach((wert) => {
    const antwort = chartStore.getAntwortById(wert.antwort_id)
    const farbe = chartStore.getFarbeById(antwort.farbe_id)

    let rect = svgContainer
      .rect(rectSize, rectSize)
      .fill(farbe.farbe)
      .move(col1X, rowY + rowHeight / 2 - rectSize / 2)
    group.add(rect)
    let text = drawTooltipText([antwort.antwort], col2X, rowY, rowHeight)
    text.addClass('text')
    group.add(text)
    let n = drawTooltipText(
      [Math.round(wert.absolut).toString()],
      dummyPosition,
      rowY,
      rowHeight,
      'end'
    )
    n.addClass('n')
    group.add(n)
    let percent = drawTooltipText(
      [wert.relativ.toFixed(1)],
      dummyPosition,
      rowY,
      rowHeight,
      'end'
    )
    percent.addClass('percent')
    group.add(percent)
    rowY += rowHeight

    if (wert.antwort_id != IDs.antwort_id) {
      let lighterFontColor = 'rgba(0, 0, 0, 0.3)'
      text.attr('fill', lighterFontColor)
      n.attr('fill', lighterFontColor)
      percent.attr('fill', lighterFontColor)
    }
  })

  let text = drawTooltipText(['Gesamt'], col2X, rowY, rowHeight)
  text.addClass('text')
  group.add(text)

  n = drawTooltipText(
    [Math.round(nTotal).toString()],
    dummyPosition,
    rowY,
    rowHeight,
    'end'
  )
  n.addClass('n')
  group.add(n)
  percent = drawTooltipText(
    [Math.round(100).toString()],
    dummyPosition,
    rowY,
    rowHeight,
    'end'
  )
  percent.addClass('percent')
  group.add(percent)

  //Position für n finden
  var textMaxWidth = 0
  let texts = svgContainer.find('.text')
  texts.forEach((text) => {
    textMaxWidth = Math.max(textMaxWidth, text.bbox().x2)
  })
  var nMaxWidth = 0
  let ns = svgContainer.find('.n')
  ns.forEach((n) => {
    nMaxWidth = Math.max(nMaxWidth, n.bbox().x2)
  })
  //Position für n setzen
  ns.forEach((n) => {
    n.ax(textMaxWidth + nMaxWidth + textOffset - dummyPosition)
  })
  //Position für % finden
  var percentMaxWidth = 0
  let percents = svgContainer.find('.percent')
  percents.forEach((percent) => {
    percentMaxWidth = Math.max(percentMaxWidth, percent.bbox().x2)
  })
  //Position für % setzen
  percents.forEach((percent) => {
    percent.ax(
      textMaxWidth +
        nMaxWidth +
        percentMaxWidth +
        2 * textOffset -
        dummyPosition
    )
  })

  const size = group.bbox()

  let topDashLine = svgContainer
    .line(col1X, padding + rowHeight, col1X + size.w, padding + rowHeight)
    .stroke({
      color: COLORS_CONST.axisDash,
      width: GENERAL_CONST.dashSize,
      dasharray: '2, 2',
      linecap: 'round'
    })

  group.add(topDashLine)
  let bottomDashLine = svgContainer
    .line(col1X, rowY, col1X + size.w, rowY)
    .stroke({
      color: COLORS_CONST.axisDash,
      width: GENERAL_CONST.dashSize,
      dasharray: '2, 2',
      linecap: 'round'
    })
  group.add(bottomDashLine)
  let rightDashLine = svgContainer
    .line(
      textMaxWidth + nMaxWidth + 1.5 * textOffset,
      padding,
      textMaxWidth + nMaxWidth + 1.5 * textOffset,
      padding + size.h
    )
    .stroke({
      color: COLORS_CONST.axisDash,
      width: GENERAL_CONST.dashSize,
      dasharray: '2, 2',
      linecap: 'round'
    })
  group.add(rightDashLine)
  bg.size(size.w + 2 * padding, size.h + 2 * padding)
  showTooltip(e, bg.bbox(), target)
}

const line = (group, e, { groupName, groupCategory }) => {
  let bg = drawBackground()
  group.add(bg)
  let rowY = row1Y
  if (groupName != groupCategory) {
    let groupCategoryText = drawTooltipText(
      [groupCategory + ':'],
      col1X,
      rowY,
      rowHeight
    )
    rowY += rowHeight
    group.add(groupCategoryText)
  }
  let groupNameText = drawTooltipText([groupName], col1X, rowY, rowHeight)
  group.add(groupNameText)
  var size = group.bbox()
  bg.size(size.w + 2 * padding, size.h + 2 * padding)
  showTooltip(e, bg.bbox())
}

const point = (group, e, { groupName, groupCategory }) => {
  //TODO
  const abs = 'Absolut: ' + SVG(e.target).attr('absolut')
  const rel = 'Relativ: ' + SVG(e.target).attr('relativ') + '%'

  let bg = drawBackground()
  group.add(bg)
  let rowY = row1Y
  if (groupName != groupCategory) {
    let groupCategoryText = drawTooltipText(
      [groupCategory + ':'],
      col1X,
      rowY,
      rowHeight
    )
    rowY += rowHeight
    group.add(groupCategoryText)
  }
  let groupNameText = drawTooltipText([groupName], col1X, rowY, rowHeight)
  rowY += rowHeight
  let absText = drawTooltipText([abs], col1X, rowY, rowHeight)
  rowY += rowHeight
  let relText = drawTooltipText([rel], col1X, rowY, rowHeight)
  group.add(groupNameText)
  group.add(absText)
  group.add(relText)
  var size = group.bbox()
  bg.size(size.w + 2 * padding, size.h + 2 * padding)
  showTooltip(e, bg.bbox())
}

const benchmark = (svgContainer, group, e) => {
  const col2X = col1X + 12

  let bg = drawBackground()
  group.add(bg)
  const benchmarks = chartStore.benchmarks

  // let colors = [
  //   BMQ_COLORS['+++'],
  //   BMQ_COLORS['++'],
  //   BMQ_COLORS['+'],
  //   BMQ_COLORS['o'],
  //   BMQ_COLORS['-'],
  //   BMQ_COLORS['--']
  // ]
  // let text = [
  //   'sehr gut',
  //   'gut',
  //   'mäßig',
  //   'kritisch',
  //   'schlecht',
  //   'sehr schlecht'
  // ]
  var rowY = row1Y
  benchmarks.forEach((bm, i) => {
    let col = svgContainer
      .rect(rectSize, rectSize)
      .fill(chartStore.getFarbeById(bm.farbe_id).farbe)
      .move(col1X, rowY + rowHeight / 2 - rectSize / 2)
    group.add(col)
    let col1 = drawTooltipText([bm.text], col2X, rowY, rowHeight)
    group.add(col1)
    rowY += rowHeight
  })
  var size = group.bbox()
  bg.size(size.w + 2 * padding, size.h + 2 * padding)
  showTooltip(e, bg.bbox())
}

/**
 * @function drawTooltipText
 * @param {string[]} nameArray - Gruppenname in Array
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Höhe der Zeile
 * @returns {object} - text-svgObject
 */
export function drawTooltipText(
  nameArray,
  xPos,
  yPos,
  rowHeight,
  anchor = 'start'
) {
  const svgContainer = SVG('#' + tooltipId)

  const fontSize = GENERAL_CONST.fontSizeTooltip
  const baseline = 'central'

  const groupNameElement = svgContainer.text(function (add) {
    nameArray.forEach((el) => {
      add
        .tspan(el)
        .font({
          size: fontSize,
          anchor,
          'dominant-baseline': baseline
        })
        .newLine()
    })
  })
  // .addClass(ID.groupName)
  // .id(makeId([ID.groupName, groupId]))

  const textBoxHeight = groupNameElement.bbox().h
  const targetOffset = (rowHeight - textBoxHeight) / 2
  groupNameElement.move(xPos, yPos + targetOffset)

  return groupNameElement
}
