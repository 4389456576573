//@ts-check
// import { drawLineXAxis } from './lineXAxis'
import { drawYAxis } from '../general/yAxis'
import { drawLineLegend } from './lineLegend'
import {
  LINE_CONST
  //  ID
} from '../constants'
import * as TOOLTIP from '../svgTooltip'
// import { makeId } from '../helper'

import { drawLineBenchmarkAndLabel } from './lineBenchmarkAndLabel'
import { drawPoint } from './point'
import { drawLine } from './line'
import { useChartStore } from '../../stores/chart'
import {
  updateLineView,
  resetLineView,
  setLineView
  // fadeOtherLineGroups
} from './lineHelper'
import { createSVG, updateSVG } from '../general/svgElement'
import { drawXAxis, drawXAxisDashLines } from '../general/xAxis'
import { drawQuestion } from '../general/questionContainer'

/**
 * Zeichnet die Linie mit allen Datenpunkten
 * @function drawLineChart
 * @param {import('../typedef').Frage} frage - Frage
 */
export function drawLineChart(frage) {
  const chartStore = useChartStore()
  /**
   * @type {import('../typedef').ID} IDs
   */
  var IDs = {}
  IDs.frage_id = frage.id

  let questionSVG = drawQuestion(frage)

  let svgContainer = createSVG()
  chartStore.svgContainer = svgContainer
  // store.commit('chart/setSVGContainer', svgContainer)

  const unterfragen = frage.unterfragen
  const antwortIdToDraw = frage.antworten.find((a) => a.reihenfolge > 0)?.id

  const rowHeight = chartStore.getRowHeightLine
  let yPos = LINE_CONST.chartAreaY

  const svgRowGroup = svgContainer.group().id('row')
  const svgLabelGroup = svgContainer.group().id('label')
  const svgBenchmarkGroup = svgContainer.group().id('benchmark')
  // Layering
  const svgLineGroup = svgContainer.group().addClass('lineGroup')
  const svgPointGroup = svgContainer.group().addClass('pointGroup')

  let standardGruppeIDs = null
  let previousUnterfrageId
  unterfragen.forEach((unterfrage, unterfrageIndex) => {
    IDs.unterfrage_id = unterfrage.id
    let { rowElement, labelElement, benchmarkElement } =
      drawLineBenchmarkAndLabel(unterfrage, yPos, IDs)

    svgRowGroup.add(rowElement)
    svgLabelGroup.add(labelElement)
    if (benchmarkElement) svgBenchmarkGroup.add(benchmarkElement)

    unterfrage.gruppen_werte.forEach((gruppenWert) => {
      if (!chartStore.getGruppeIsSelectedById(gruppenWert.gruppe_id)) {
        // Gruppe überspringen
        return
      }
      const wert = gruppenWert.werte.find(
        (w) => w.antwort_id === antwortIdToDraw
      )
      const gruppe = chartStore.getGruppeByGruppenWert(gruppenWert)
      const kategorie = chartStore.getKategorieById(gruppenWert.kategorie_id)
      const farbe = chartStore.getFarbeById(gruppe.farbe_id)

      IDs.kategorie_id = kategorie.id
      IDs.gruppe_id = gruppe.id
      IDs.benchmark_id = gruppenWert.benchmark_id
      //draw Point
      const point = drawPoint(wert, farbe, yPos, IDs)

      if (gruppe.standard && !standardGruppeIDs) {
        standardGruppeIDs = { ...IDs }
      }

      point.on('mouseover', (e) => {
        TOOLTIP.init(e, {
          groupName: gruppe.name,
          groupCategory: kategorie.name
        })
        updateLineView(e)
      })
      point.on('mouseleave', () => {
        TOOLTIP.hide()
        resetLineView()
      })
      point.on('click', (e) => {
        setLineView(e)
      })
      svgPointGroup.add(point)

      //draw Line
      if (unterfrageIndex > 0) {
        const line = drawLine(
          svgPointGroup,
          wert,
          farbe,
          IDs,
          previousUnterfrageId
        )

        line.on('mouseover', (e) => {
          TOOLTIP.init(e, {
            groupName: gruppe.name,
            groupCategory: kategorie.name
          })
          updateLineView(e)
        })
        line.on('mouseleave', () => {
          TOOLTIP.hide()
          resetLineView()
        })
        line.on('click', (e) => {
          setLineView(e)
        })
        svgLineGroup.add(line)
      }
    })

    yPos += rowHeight
    previousUnterfrageId = IDs.unterfrage_id
  })

  drawXAxisDashLines(
    svgContainer,
    LINE_CONST.chartAreaX,
    LINE_CONST.chartAreaWidth,
    svgRowGroup.bbox().h,
    { min: 0, max: 100, width: 20 }
  )

  let { width: xAxisWidth } = drawXAxis(
    LINE_CONST.chartAreaX,
    LINE_CONST.chartAreaWidth,
    {
      min: 0,
      max: 100,
      width: 20
    },
    true
  )

  drawYAxis(svgContainer, svgRowGroup.bbox().h)
  drawLineLegend(unterfragen[0].gruppen_werte, IDs)
  setLineView(null, standardGruppeIDs)

  let width = Math.max(LINE_CONST.totalWidth, xAxisWidth)
  updateSVG(svgContainer, width, svgRowGroup.bbox().h)
  // questionSVG.svgContainer.findOne('#question-background').width(width)
  updateSVG(questionSVG.svgContainer, width, questionSVG.height)
}
