<template>
  <v-card class="full-height-card">
    <v-card-subtitle>
      <v-row align="center" justify="space-between">
        <v-col cols="auto pr-0">Gruppen</v-col>
        <v-col v-if="!disabled" cols="auto">
          <v-switch
            dense
            :input-value="showNotAnswered"
            @change="showNotAnswered = !showNotAnswered"
            hide-details
            color="#005475"
          >
            <template v-slot:label>
              <div class="text-caption d-none d-lg-block pl-0">
                "keine Angabe" anzeigen
              </div>
              <div class="text-caption d-lg-none pl-0">"k. A." anzeigen</div>
            </template></v-switch
          ></v-col
        >
      </v-row>
    </v-card-subtitle>
    <v-card-text v-if="kategorienFiltered && !disabled">
      <v-treeview
        :value="selectedGroupIds"
        hoverable
        dense
        selectable
        @input="setSelectedGroupIds"
        :items="kategorienFiltered"
        selected-color="bmq-blue"
        item-disabled="standard"
        item-children="gruppen"
        indeterminate-icon="mdi-checkbox-marked-outline"
        class="treeview-content"
      >
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import { useChartStore } from '../stores/chart'
import { storeToRefs } from 'pinia'
import { onMounted, computed, ref, watch } from 'vue'
export default {
  name: 'GroupSelect',
  setup() {
    const chartStore = useChartStore()
    const {
      selectedGroupIds,
      selectedQuestion,
      showNotAnswered,
      kategorienFiltered,
      defaultGroupId
    } = storeToRefs(chartStore)

    const disabled = computed(() => selectedQuestion.value?.typ === 'kuchen')
    function setSelectedGroupIds(payload) {
      selectedGroupIds.value = payload
    }

    return {
      showNotAnswered,
      selectedGroupIds,
      kategorienFiltered,
      disabled,
      setSelectedGroupIds
    }
  }
}
</script>

<style lang="scss">
.treeview-content {
  display: flex;
  flex-wrap: wrap;
  .v-treeview-node__root {
    padding: 0;
  }
  .v-treeview-node__toggle {
    width: 20px;
  }
  .v-treeview-node__checkbox {
    width: 20px;
    margin: 0;
  }
  .mdi:before {
    font-size: 20px !important;
  }
}

.treeview-content .v-treeview-node--disabled .theme--light {
  pointer-events: none;
}

.v-treeview-node {
  flex-basis: 100%;
}

.v-treeview-node__children {
  display: flex;
  flex-wrap: wrap;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.v-input--selection-controls {
  margin: 0px;
  padding: 0px;
}
</style>
