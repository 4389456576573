<template>
  <v-container fluid class="fill-height">
    <v-row justify="center" class="fill-height">
      <v-col cols="11">
        <v-card flat>
          <v-card-title> Berichte und Präsentationen </v-card-title>
          <v-card-text v-if="myFiles" class="my-4">
            <v-row justify="center">
              <v-col cols="12">
                <File v-for="file in myFiles" :key="file" :file-url="file" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="11">
                <v-btn :to="{ name: 'Welcome' }">Zurück zur Übersicht</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useComStore } from '../stores/communication'
import File from '../components/File'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { storeToRefs } from 'pinia'

export default {
  name: 'ReportsPage',
  components: { File },
  setup() {
    const comStore = useComStore()
    const { files } = storeToRefs(comStore)

    const currentRoute = useRoute()
    const folder = computed(() => currentRoute.params.folder)
    const myFiles = computed(() =>
      !!files.value && !!folder.value ? files.value[folder.value] : null
    )

    onMounted(() => {
      if (!files.value) {
        comStore.getFiles()
      }
    })

    return { myFiles }
  }
}
</script>

<style scoped></style>
