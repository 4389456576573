//@ts-check
import { LINE_CONST, ID, COLORS_CONST } from '../constants'
import { useChartStore } from '../../stores/chart'
import { makeId } from '../helper'

/**
 * Zeichnet alle Punkte einer Gruppe für eine Zeile
 * @function drawPoint
 * @param {import('../typedef').Wert} wert -item Objekt
 * @param {import('../typedef').Farbe} farbe -Farbe der Gruppe
 * @param {number} yPos - Koordinaten für Y-Position
 * @param {import('../typedef').ID} IDs - IDs
 */
export function drawPoint(wert, farbe, yPos, IDs) {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer
  const rowHeight = chartStore.getRowHeightLine
  const fillColor = COLORS_CONST.white
  const xLeft = LINE_CONST.chartAreaX
  const xWidth = LINE_CONST.chartAreaWidth

  const pointElement = svgContainer
    .circle(LINE_CONST.pointDiameter)
    .fill(fillColor)
    .stroke({ color: farbe.farbe, width: LINE_CONST.pointStroke })
    .move(
      xLeft + (wert.relativ * xWidth) / 100 - LINE_CONST.pointDiameter / 2,
      yPos + rowHeight / 2 - LINE_CONST.pointDiameter / 2
    )
    .addClass(makeId([ID.line.point, IDs.unterfrage_id, IDs.gruppe_id]))
    .addClass(ID.line.point)
    .attr('IDs', JSON.stringify(IDs))
    .attr('absolut', wert.absolut)
    .attr('relativ', wert.relativ)
    .attr('fill-opacity', 0)

  return pointElement
}
