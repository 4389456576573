import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        'bmq-blue': '#005475',
        'bmq-lightblue': '#e1ebef',
        'bmq-footer': '#3a3a3a'
      }
    }
  }
})
export default vuetify
