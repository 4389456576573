<template>
  <v-row class="fill-height ma-0">
    <v-col cols="auto" class="px-3 elevation-1">
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showFullscreenTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toggleFullscreen()" icon v-bind="attrs" v-on="on">
                <v-icon v-if="fullscreen">mdi-arrow-collapse-all</v-icon>
                <v-icon v-else>mdi-arrow-expand-all</v-icon>
              </v-btn>
            </template>
            <span>Vollbild-Modus ein/aus</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showSidebarTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="sidebarHidden = !sidebarHidden"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="sidebarHidden">mdi-menu</v-icon>
                <v-icon v-else>mdi-menu-open</v-icon>
              </v-btn>
            </template>
            <span>Fragen und Gruppen ein-/ausblenden</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showPreviousTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="previousQuestion()" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Vorherige Frage</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showNextTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="nextQuestion()" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>Nächste Frage</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showZoomInTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="zoom(1)" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
            </template>
            <span>Grafik hereinzoomen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showZoomTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="zoom(0)" icon v-bind="attrs" v-on="on">
                100%
              </v-btn>
            </template>
            <span>Zoomstufe auf 100% zurücksetzen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showZoomOutTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="zoom(-1)" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
            </template>
            <span>Grafik herauszoomen</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showAnimationTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="toggleStickyAnimation()"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="stickyAnimationActive">mdi-toggle-switch</v-icon>
                <v-icon v-else>mdi-toggle-switch-off-outline</v-icon>
              </v-btn>
            </template>
            <span>Unterfragen fixieren ein-/ausschalten</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showTooltipTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="svgTooltipsActive = !svgTooltipsActive"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="svgTooltipsActive">mdi-toggle-switch</v-icon>
                <v-icon v-else>mdi-toggle-switch-off-outline</v-icon>
              </v-btn>
            </template>
            <span>Tooltips ein-/ausschalten</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showDownloadPngTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="downloadChart('png')"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <div class="vertical-button">
                  <v-icon>mdi-download</v-icon>
                  <span class="download-type">PNG</span>
                </div>
              </v-btn>
            </template>
            <span>Grafik als PNG herunterladen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showDownloadSvgTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="downloadChart('svg')"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <div class="vertical-button">
                  <v-icon>mdi-download</v-icon>
                  <span class="download-type">SVG</span>
                </div>
              </v-btn>
            </template>
            <span>Grafik als SVG herunterladen</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col>
          <v-tooltip v-model="showHelpTooltip" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="showAllTooltips()" icon v-bind="attrs" v-on="on">
                <v-icon v-if="helpActive">mdi-help-circle</v-icon>
                <v-icon v-else>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Alle Erklärungen ein-/ausblenden</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <div v-if="offline">
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col>
            <v-tooltip v-model="showDownloadAllSvgTooltip" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="downloadAll('svg')"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="vertical-button">
                    <v-icon>mdi-download</v-icon>
                    <span class="download-type">ALLE</span>
                  </div>
                </v-btn>
              </template>
              <span>Alle Grafiken als SVG herunterladen</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-overlay :value="overlay">
      <v-row class="justify-center">
        <v-progress-circular
          :rotate="-90"
          :value="downloadProgress"
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row class="justify-center">
        SVGs {{ downloadProgress < 99 ? 'werden' : 'wurden' }} erstellt...
      </v-row>
      <v-row class="justify-center">
        <v-btn
          v-if="downloadProgress > 99"
          color="primary"
          @click="overlay = false"
        >
          Schließen
        </v-btn>
        <v-btn v-else color="error" @click="stopDownload"> Abbrechen </v-btn>
      </v-row>
    </v-overlay>
  </v-row>
</template>

<script>
import { useSettingsStore } from '../stores/settings'
import { useComStore } from '../stores/communication'
import { useChartStore } from '../stores/chart'
import { ref, computed, nextTick, onBeforeMount, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import svgToImage from '../plugins/downloadChart'
import { zoomSVG } from '../plugins/general/svgElement'

export default {
  name: 'SettingsSidebar',
  components: {},
  setup() {
    const comStore = useComStore()
    const { offline } = storeToRefs(comStore)

    const settingsStore = useSettingsStore()
    const {
      fullscreen,
      stickyAnimationActive,
      sidebarHidden,
      svgTooltipsActive
    } = storeToRefs(settingsStore)

    function toggleFullscreen() {
      const elem = document.body
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        fullscreen.value = true
        if (elem.requestFullScreen) {
          elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        fullscreen.value = false
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }

    function toggleStickyAnimation() {
      stickyAnimationActive.value = !stickyAnimationActive.value
      //* Reset all animations
      let containers = document.getElementsByClassName('subquestionContainer')
      Array.prototype.forEach.call(containers, (el) => {
        if (stickyAnimationActive.value) {
          el.classList.add('sticky')
        } else {
          el.classList.remove('sticky')
        }
      })
    }

    const chartStore = useChartStore()
    const {
      selectedQuestionId,
      fragen,
      selectedQuestion,
      selectedQuestionIndex,
      zoomFactor
    } = storeToRefs(chartStore)

    function downloadChart(fileType) {
      let dom = document.getElementById('fullChartContainer')
      // scroll to top before download
      dom.scrollTop = 0
      let chart = document.getElementById('chartContainer')
      let filename = selectedQuestion.value.praefix

      return svgToImage(chart, filename, fileType)
    }
    const overlay = ref(false)
    const downloadProgress = ref(0)
    const keepDownloading = ref(true)
    async function downloadAll() {
      overlay.value = true
      for (let index = 0; index < fragen.value.length; index++) {
        if (!keepDownloading.value) break
        const frage = fragen.value[index]
        selectedQuestionId.value = frage.id
        await nextTick()
        await downloadChart('svg')
        downloadProgress.value = Math.round(
          (index / (fragen.value.length - 1)) * 100
        )
      }
      keepDownloading.value = true
    }

    //arrowkeys control
    onBeforeMount(() => {
      window.addEventListener('keyup', handleKeydown, null)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('keyup', handleKeydown, null)
    })
    function handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          previousQuestion()
          break
        case 39:
          nextQuestion()
          break
      }
    }

    function stopDownload() {
      keepDownloading.value = false
      downloadProgress.value = 0
      overlay.value = false
    }

    function previousQuestion() {
      if (selectedQuestionIndex.value > 0) {
        selectedQuestionId.value =
          fragen.value[selectedQuestionIndex.value - 1].id
      }
    }

    function nextQuestion() {
      if (selectedQuestionIndex.value < fragen.value.length - 1) {
        selectedQuestionId.value =
          fragen.value[selectedQuestionIndex.value + 1].id
      }
    }

    const isZooming = ref(false)
    function zoom(val) {
      if (isZooming.value) return
      isZooming.value = true
      const svgList = document.querySelectorAll('#chartContainer svg')

      if (val > 0) {
        zoomFactor.value += 0.05
      } else if (val < 0) {
        zoomFactor.value -= 0.05
      } else {
        zoomFactor.value = 1
      }
      svgList.forEach((svg) => {
        svg.classList.toggle('animation')
        zoomSVG(svg, zoomFactor.value)
      })

      setTimeout(() => {
        svgList.forEach((svg) => {
          svg.classList.toggle('animation')
        })
        isZooming.value = false
      }, 1000)
    }

    //Tooltips
    const showFullscreenTooltip = ref(false)
    const showSidebarTooltip = ref(false)
    const showPreviousTooltip = ref(false)
    const showNextTooltip = ref(false)
    const showZoomInTooltip = ref(false)
    const showZoomOutTooltip = ref(false)
    const showZoomTooltip = ref(false)
    const showAnimationTooltip = ref(false)
    const showTooltipTooltip = ref(false)
    const showDownloadPngTooltip = ref(false)
    const showDownloadSvgTooltip = ref(false)
    const showDownloadAllSvgTooltip = ref(false)
    const showHelpTooltip = ref(false)
    const helpActive = ref(false)

    function showAllTooltips() {
      helpActive.value = !helpActive.value
      showFullscreenTooltip.value = helpActive.value
      showSidebarTooltip.value = helpActive.value
      showPreviousTooltip.value = helpActive.value
      showNextTooltip.value = helpActive.value
      showZoomInTooltip.value = helpActive.value
      showZoomOutTooltip.value = helpActive.value
      showZoomTooltip.value = helpActive.value
      showAnimationTooltip.value = helpActive.value
      showHelpTooltip.value = helpActive.value
      showTooltipTooltip.value = helpActive.value
      showDownloadPngTooltip.value = helpActive.value
      showDownloadSvgTooltip.value = helpActive.value
    }

    return {
      fullscreen,
      toggleFullscreen,
      stickyAnimationActive,
      toggleStickyAnimation,
      sidebarHidden,
      downloadChart,
      svgTooltipsActive,
      offline,
      downloadAll,
      overlay,
      downloadProgress,
      stopDownload,
      previousQuestion,
      nextQuestion,
      zoom,
      showAllTooltips,
      showFullscreenTooltip,
      showSidebarTooltip,
      showPreviousTooltip,
      showNextTooltip,
      showZoomInTooltip,
      showZoomOutTooltip,
      showZoomTooltip,
      showAnimationTooltip,
      showTooltipTooltip,
      showDownloadPngTooltip,
      showDownloadSvgTooltip,
      showDownloadAllSvgTooltip,
      showHelpTooltip,
      helpActive
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-button {
  display: flex;
  flex-direction: column;
}

.vertical-button:first-child {
  z-index: 1;
}
.download-type {
  z-index: 2;
  margin-top: -10px;
  font-size: 9px;
  letter-spacing: normal;
  background-color: white;
}
</style>
