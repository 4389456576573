<template>
  <v-container fluid class="fill-height">
    <v-row justify="center" class="fill-height">
      <v-col cols="8">
        <v-card flat min-height="400px">
          <v-card-title> Login </v-card-title>
          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="login()"
          >
            <v-card-text>
              <v-text-field
                v-model="username"
                :rules="nameRules"
                type="text"
                label="Name"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Passwort"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn type="submit" :disabled="!valid">Login</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
          <v-card-text class="text-caption">
            Mit Klick auf Login akzeptieren Sie die Speicherung von notwendigen
            funktionalen Cookies.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useComStore } from '../stores/communication'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

import { ref } from 'vue'
export default {
  name: 'LoginPage',
  setup() {
    const comStore = useComStore()

    const username = ref('')
    const password = ref('')
    const showPassword = ref(false)
    const valid = ref(false)
    const refForm = ref(null)

    const router = useRouter()
    function login() {
      if (refForm.value.validate()) {
        var params = { username: username.value, password: password.value }

        comStore
          .login(params)
          .then((toRoute) => (toRoute ? router.push(toRoute) : ''))
      }
    }

    return {
      refForm,
      username,
      password,
      showPassword,
      valid,
      login,
      nameRules: [(v) => !!v || 'Name darf nicht leer sein'],
      passwordRules: [(v) => !!v || 'Passwort darf nicht leer sein']
    }
  }
}
</script>

<style scoped></style>
