//@ts-check
import { BAR_CONST, ID, COLORS_CONST } from '../constants'
import { makeId } from '../helper'
// import store from '../../store'
/**
 * @function drawBarBackground
 * @param {object} container - SVG
 * @param {import('../typedef').chartData} data - ID der Gruppe
 * @param {number} yPos - Y-Koordinate
 * @param {number} subquestionId - ID der Unterfrage
 * @param {number} rowHeight - Zeilenhöhe
 * @returns {object} - bar-svgObject
 */
export function drawBarRowBackground(
  container,
  data,
  yPos,
  subquestionId,
  rowHeight
) {
  const svgContainer = container

  const groupId = data.groupId
  let background = svgContainer
    .rect(BAR_CONST.totalWidth, rowHeight)
    .fill(COLORS_CONST.barRowBackground)
    .stroke({ color: COLORS_CONST.barRowBackgroundStroke, width: 0 })
    .addClass(ID.bar.rowBackground)
    .id(makeId([ID.bar.rowBackground, subquestionId, groupId]))
    .move(0, yPos)
  return background
}
