import { BAR_CONST, ID } from '../constants'
import * as TOOLTIP from '../svgTooltip'

/**
 * @function sideInfoHeader
 * @param {object} container - Der SVG() Container
 * @returns {object} - svgObject
 */
export function drawSideInfoHeader(container) {
  const svgContainer = container //store.state.SVG_CONTAINER
  let sideInfoHeader = svgContainer.group()
  //Überschriften n und kA
  let nTextEl = drawSideInfoHeaderText(
    svgContainer,
    BAR_CONST.sideInfoHeaderText_n,
    BAR_CONST.sideInfoX_n,
    ID.n
  )
  nTextEl.on('mouseover', TOOLTIP.init)
  nTextEl.on('mouseleave', TOOLTIP.hide)

  let kaTextEl = drawSideInfoHeaderText(
    svgContainer,
    BAR_CONST.sideInfoHeaderText_kA,
    BAR_CONST.sideInfoX_kA,
    ID.kA
  )
  kaTextEl.on('mouseover', TOOLTIP.init)
  kaTextEl.on('mouseleave', TOOLTIP.hide)

  sideInfoHeader.add(nTextEl)
  sideInfoHeader.add(kaTextEl)
  return sideInfoHeader
}

/**
 * @function drawSideInfoHeaderText
 * @param {object} container - Der SVG() Container
 * @param {string} text - Text in Pfeil
 * @param {Number} xPos - X-Pos
 * @param {string} className - Klasse
 * @returns {object} - bar-svgObject
 */
function drawSideInfoHeaderText(container, text, xPos, className) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.sideInfoFontSize
  const anchor = BAR_CONST.sideInfoAnchor
  const baseline = BAR_CONST.sideInfoBaseline

  const yPos = BAR_CONST.sideInfoHeaderY
  const nameArray = [text]

  const sideInfoHeaderTextElement = svg
    .text(function (add) {
      nameArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
          .addClass(className)
      })
    })
    .amove(xPos, yPos)

  return sideInfoHeaderTextElement
}
