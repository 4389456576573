<template>
  <div id="chartContainerParent" class="mx-auto">
    <div id="chartContainer"></div>
  </div>
</template>

<script>
import { draw } from '../plugins/svgDrawer'
import { onMounted, computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useChartStore } from '../stores/chart'

export default {
  name: 'ChartComponent',
  setup() {
    const chartStore = useChartStore()
    const {
      fragen,
      selectedGroupIds,
      selectedQuestionId,
      selectedQuestion,
      chartData
    } = storeToRefs(chartStore)

    function drawSVG() {
      // clear svg
      var div = document.getElementById('chartContainer')
      while (div.firstChild) {
        div.removeChild(div.firstChild)
      }

      draw()
    }
    watch([selectedGroupIds, selectedQuestionId], drawSVG)

    onMounted(() => drawSVG())

    return { fragen, selectedGroupIds, selectedQuestionId }
  }
}
</script>

<style lang="scss">
.scrollable {
  overflow-y: auto;
}

.animation {
  transition: all 1s ease-in-out;
}

//axis
.svg-axis {
  position: absolute;
}

#chartContainer {
  display: inline-grid;
  background: white;
}

.questionContainer {
  top: 0px;
  position: sticky;
}
.arrowContainer {
  position: sticky;
}
.subquestionContainer {
  &.sticky {
    position: sticky;
  }
}
.tooltipContainer {
  position: absolute;
  &.show {
    animation: pop-in 0.5s;
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
