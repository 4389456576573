//@ts-check
import { BMQ_BENCHMARK_ARRAY, ID } from './constants'

var _ = require('lodash')

export function findLeftSpace(string, index) {
  // index = Math.min(string.length, index)
  for (let i = index; i >= 0; i--) {
    if (string[i] == ' ') {
      return i
    }
  }
  return -1
}

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0])
}

export function add(array, value) {
  if (array.indexOf(value) === -1) {
    array.push(value)
  }
}

export function remove(array, value) {
  var index = array.indexOf(value)
  if (index !== -1) {
    array.splice(index, 1)
  }
}

export function createDataValues(mainquestion) {
  const mainCount = mainquestion.length
  var values = []
  for (let m = 1; m <= mainCount; m++) {
    var subCount = mainquestion[m - 1].subquestion.length
      ? mainquestion[m - 1].subquestion.length
      : 1
    var answerCount = mainquestion[m - 1].answer.length
    for (let s = 1; s <= subCount; s++) {
      for (let a = 1; a <= answerCount; a++) {
        let rat =
          BMQ_BENCHMARK_ARRAY[
            Math.round(Math.random() * (BMQ_BENCHMARK_ARRAY.length - 1))
          ]
        let v = {
          mainquestionId: m,
          subquestionId: s,
          answerId: a,
          abs: Math.round(Math.random() * 50),
          rel: Math.round(Math.random() * 50),
          rat: rat
        }
        values.push(v)
      }
    }
  }
  return values
}

/**
 * Parses given Array of string or number and concenates them to an id string
 * @param {Array<string|number>} parts
 * @returns {string}
 */
export function makeId(parts) {
  const separator = ID.separator
  let id = ''
  for (let index = 0; index < parts.length - 1; index++) {
    id += parts[index] + separator
  }
  id += parts[parts.length - 1]
  return id
}

/**
 * Parses given id string and returns all ids as integer
 * @param {string} id
 * @returns {{item:number, questionId:number,subquestionId:number,groupId:number, answerId:number}}
 */
export function splitId(id) {
  const separator = ID.separator
  const parts = id.split(separator)
  const item = parts[0] ? parseInt(parts[0]) : 0
  const questionId = parts[1] ? parseInt(parts[1]) : 0
  const subquestionId = parts[2] ? parseInt(parts[2]) : 0
  const groupId = parts[3] ? parseInt(parts[3]) : 0
  const answerId = parts[4] ? parseInt(parts[4]) : 0
  return { item, questionId, subquestionId, groupId, answerId }
}

/**
 * sets svg elements opacity for hover effect
 * @param {object} element
 * @param {boolean} reset
 */
export function setHoverEffect(element, reset = false) {
  if (typeof element.opacity != 'function') {
    throw new Error('Given element is no svgdotjs object')
  }
  if (reset) {
    element.opacity(0)
  } else {
    element.opacity(0.75)
  }
}
