//@ts-check
import { BAR_CONST, COLORS_CONST, LINE_CONST, OFFSET } from '../constants'
import { createSVG, updateSVG } from './svgElement'
import { findLeftSpace } from '../helper'

const settings = {
  height: 80,
  fontSize: BAR_CONST.labelFontSize,
  xPosPrefix: 20,
  xPosQuestion: 30,
  backgroundColor: 'rgb(216, 232, 200)'
}

/**
 * Zeichnet den Container für die Frage mit Hintergrund
 * @param {import('../typedef').Frage} frage - Fragen Objekt aus JSON
 * @returns {object} - svg container object
 */
export function drawQuestion(frage) {
  const svgContainer = createSVG('questionContainer', 'questionContainer')
  const height = settings.height
  const width = BAR_CONST.totalWidth
  // frage.typ === 'balken' ? BAR_CONST.totalWidth : LINE_CONST.totalWidth
  const fontSize = settings.fontSize
  const anchor = 'start'
  const baseline = 'hanging'
  const font = {
    size: fontSize,
    anchor,
    'dominant-baseline': baseline
  }
  const svgGroup = svgContainer.group()
  const xPosPrefix = settings.xPosPrefix
  let yPos = height / 2

  svgGroup.add(
    svgContainer
      .rect(width, height)
      .fill({ color: 'rgb(216, 232, 200)' })
      .move(0, 0)
    // .id('question-background')
  )
  // create "margin-bottom"
  svgGroup.add(
    svgContainer
      .rect(width, OFFSET.question.bottom)
      .fill({ color: COLORS_CONST.white })
      .move(0, height)
  )

  let words = frage.frage.trim().split(' ')

  const prefixElement = svgContainer.text(frage.praefix).font(font)

  const xPosQuestion = prefixElement.bbox().x2 + settings.xPosQuestion
  let questionElement = svgContainer.text(frage.frage).font(font)

  let breakpoint = words.length - 1
  let labelParts = []

  let safety = 0
  while (
    questionElement.bbox().x2 + xPosQuestion > width - settings.xPosPrefix &&
    safety < 10
  ) {
    questionElement.remove()
    labelParts = [words.slice(0, breakpoint), words.slice(breakpoint)]

    questionElement = svgContainer.text(function (add) {
      labelParts.forEach((el) => {
        add
          .tspan(el.join(' '))
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })

    breakpoint--
    safety++
  }

  svgGroup.add(questionElement)
  svgGroup.add(prefixElement)

  let questionHeight = questionElement.bbox().h
  yPos = yPos - questionHeight / 2

  questionElement.amove(xPosQuestion, yPos)
  prefixElement.amove(xPosPrefix, yPos)

  let boxHeight = svgGroup.bbox().h
  updateSVG(svgContainer, width, boxHeight)
  return { svgContainer, width, height: boxHeight }
}
