import { BAR_CONST } from '../constants'

/**
 * @function sideInfoText
 * @param {object} container - Der SVG() Container
 * @param {string[]} n - n-Text
 * @param {string[]} kA - k.A.-Text
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Zeilenhöhe
 * @returns {object} - svgObject
 */
export function drawSideInfoText(container, n, kA, yPos, rowHeight) {
  const svgContainer = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.sideInfoFontSize
  const anchor = BAR_CONST.sideInfoAnchor
  const baseline = BAR_CONST.sideInfoBaseline

  let sideInfoTextElement = svgContainer.group()
  const nTextElement = svgContainer
    .text(function (add) {
      n.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(BAR_CONST.sideInfoX_n, yPos + rowHeight / 2)

  const kATextElement = svgContainer
    .text(function (add) {
      kA.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(BAR_CONST.sideInfoX_kA, yPos + rowHeight / 2)

  sideInfoTextElement.add(nTextElement)
  sideInfoTextElement.add(kATextElement)
  return sideInfoTextElement
}
