//@ts-check
// import { drawPoint } from './point'
// import { drawLineItem } from './lineItem'
import { ID, LINE_CONST } from '../constants'
// import * as TOOLTIP from '../svgTooltip'
import { useChartStore } from '../../stores/chart'

import { makeId } from '../helper'

// import {
//   updateLineView,
//   resetLineView,
//   highlightLine,
//   deHighlightLine
// } from './lineHelper'

/**
 * Zeichnet die Linie mit allen Datenpunkten
 * @function drawLine
 * @param {object} svgPointGroup -SVG Gruppe mit allen bisherigen Punkten
 * @param {import('../typedef').Wert} wert -item Objekt
 * @param {import('../typedef').Farbe} farbe - Farbe der Gruppe
 *   @param {import('../typedef').ID} IDs - IDs
 *   @param {number} previousUnterfrageId - ID der vorherigen Unterfrage

 */
export function drawLine(
  svgPointGroup,
  wert,
  farbe,
  IDs,
  previousUnterfrageId
) {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer
  const previousPoint = svgContainer.findOne(
    '.' + makeId([ID.line.point, previousUnterfrageId, IDs.gruppe_id]),
    svgPointGroup
  )
  const point = svgPointGroup.findOne(
    '.' + makeId([ID.line.point, IDs.unterfrage_id, IDs.gruppe_id])
  )

  const lineElement = svgContainer
    .line(
      previousPoint.attr('cx'),
      previousPoint.attr('cy'),
      point.attr('cx'),
      point.attr('cy')
    )
    .stroke({
      color: farbe.farbe,
      width: LINE_CONST.lineStrokeWidth,
      linecap: 'round'
    })
    .addClass(makeId([ID.line.line, IDs.gruppe_id]))
    .addClass(ID.line.line)
    .attr('IDs', JSON.stringify(IDs))

  return lineElement
}
