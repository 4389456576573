<template>
  <v-container fluid class="pa-0 chart-container">
    <v-row class="fill-height no-gutters" v-if="fragen">
      <v-col cols="auto">
        <SettingsSidebar />
      </v-col>
      <v-col cols="auto">
        <v-row class="fill-height flex-nowrap viewer-content pa-0" no-gutters>
          <!-- Left SideBar -->

          <!-- Fragen und Gruppen -->
          <v-col cols="12" sm="3" v-show="!sidebarHidden">
            <!-- Fragen -->
            <v-row class="half-height-row pa-1" no-gutters>
              <v-col class="fill-height">
                <QuestionSelect />
              </v-col>
            </v-row>
            <!-- Gruppen -->
            <v-row class="half-height-row pa-1" no-gutters>
              <v-col class="fill-height">
                <GroupSelect />
              </v-col>
            </v-row>
          </v-col>
          <!-- Main -->
          <v-col cols="12" :sm="sidebarHidden ? 12 : 9">
            <v-row class="fill-height flex-nowrap py-1" no-gutters>
              <v-col cols="12" class="fill-height">
                <v-card id="fullChartContainer" class="full-height-card">
                  <ChartComponent v-if="fragen" />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onBeforeMount, computed, ref } from 'vue'
import { storeToRefs } from 'pinia'

import GroupSelect from '../components/GroupSelect.vue'
import SettingsSidebar from '../components/SettingsSidebar.vue'
import QuestionSelect from '../components/QuestionSelect.vue'
import ChartComponent from '../components/ChartComponent.vue'
import { useComStore } from '../stores/communication'
import { useChartStore } from '../stores/chart'
import { useSettingsStore } from '../stores/settings'

export default {
  name: 'ChartPage',
  components: {
    QuestionSelect,
    ChartComponent,
    GroupSelect,
    SettingsSidebar
  },
  setup() {
    const comStore = useComStore()
    const chartStore = useChartStore()
    const { fragen, selectedQuestionId, selectedQuestionIndex } =
      storeToRefs(chartStore)

    const settingsStore = useSettingsStore()
    const { sidebarHidden } = storeToRefs(settingsStore)

    onBeforeMount(() => {
      if (!comStore.sourceJson) {
        comStore.getData().then(() => {
          chartStore.initStoreWithJson()
        })
      }
    })
    return {
      fragen,
      selectedQuestionId,
      selectedQuestionIndex,
      sidebarHidden
    }
  }
}
</script>

<style scoped>
.bmq-text {
  font-size: 14px !important;
}
.half-height-row {
  height: 50%;
  overflow-y: auto;
}
.full-height-card {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.silbentrennung {
  hyphens: auto;
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-chars: auto 3;
  -webkit-hyphenate-limit-lines: 4;
  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: auto 3;
  -ms-hyphenate-limit-lines: 4;
}

.chart-header {
  /* max-height: 10%; */
  background-color: rgb(216, 232, 200);
  height: 86px;
}
.chart-body {
  /* height: 90%; */
  max-width: 100%;
  overflow: auto;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.viewer-content {
  position: absolute;
  height: 100%;
  /* width: calc(100% - 60px); */
  /* margin-left: 60px; */
}
.chart-container {
  height: 100%;
  display: flex;
}
</style>
