import { LINE_CONST, COLORS_CONST, ID } from '../constants'
import { useChartStore } from '../../stores/chart'
import { makeId } from '../helper'
/**
 * @function drawLineBackground
 * @param {object} container - Der SVG() Container
 * @param {number} yPos - Y-Koordinate
 * @returns {object} - bar-svgObject
 */
export function drawLineBackground(subquestionId, yPos) {
  const chartStore = useChartStore()

  const container = chartStore.getSVGContainer
  const rowHeight = chartStore.getRowHeightLine

  return container
    .rect(
      LINE_CONST.chartAreaWidth,
      rowHeight - LINE_CONST.whitespaceOffset * 2
    )
    .fill(COLORS_CONST.background)
    .move(LINE_CONST.chartAreaX, yPos + LINE_CONST.whitespaceOffset)
    .addClass(ID.line.background)
    .id(makeId([ID.line.background, subquestionId]))
}
