import colors from 'vuetify/lib/util/colors'

export const GLOBAL_DEFAULTS = {
  defaultGroupId: 1,
  defaultCategoryName: 'Alle'
}

export const BMQ_BENCHMARK_ARRAY = ['--', '-', 'o', '+', '++', '+++']

export const BMQ_COLORS = {
  raender: 'rgb(177,178,180)', //Farbe der Ränder von Pfeilen und Balken
  hintergrund: 'rgb(240,240,240)', //die schattierten Flaechen

  //Balkenfarben von Positiv über k.A. nach Negativ
  dunkelmarinblau: 'rgb(29,86,167)',
  mittelmarinblau: 'rgb(42,121,190)',
  dunkelblau: 'rgb(0,158,224)',
  //hellmarinblau: 'rgb(0,158,224)',
  mittelblau: 'rgb(94,197,237)',
  //tuerkisblau: 'rgb(94,197,237)',
  hellblau: 'rgb(162,218,244)',
  keine_angabe_grau: 'rgb(204,204,203)',
  hellgelb: 'rgb(255,255,232)',
  mittelgelb: 'rgb(255,249,150)',
  dunkelgelb: 'rgb(255,236,0)',
  hellorange: 'rgb(255,204,0)',
  dunkelorange: 'rgb(254,163,0)',

  //grau: 'rgb(177,178,180)',

  Alter1: 'rgb(153,25,27)',
  Alter2: 'rgb(227,29,34)',
  Alter3: 'rgb(255,108,0)',
  Alter4: 'rgb(253,204,27)',
  Alter5: 'rgb(251,248,205)',

  Geschlecht1: 'rgb(153,25,27)',
  Geschlecht2: 'rgb(253,204,27)',

  //Benchmarks
  '--': colors.red.darken3,
  '-': colors.red.lighten2,
  o: colors.orange.darken2,
  '+': colors.orange.lighten2,
  '++': colors.green.lighten2,
  '+++': colors.green.darken4
}

export const getColorArray = (answers) => {
  const positiveCount = answers.filter((a) => a.order > 0).length
  const negativeCount = answers.filter((a) => a.order < 0).length
  var colors = {}

  colors['5'] = BMQ_COLORS.dunkelmarinblau
  colors['4'] = BMQ_COLORS.mittelmarinblau

  switch (positiveCount) {
    case 1:
      colors['1'] = BMQ_COLORS.dunkelblau
      break
    case 2:
      colors['2'] = BMQ_COLORS.dunkelblau
      colors['1'] = BMQ_COLORS.mittelblau
      break
    default:
      colors['3'] = BMQ_COLORS.dunkelblau
      colors['2'] = BMQ_COLORS.mittelblau
      colors['1'] = BMQ_COLORS.hellblau
      break
  }

  switch (negativeCount) {
    case 1:
      colors['-1'] = BMQ_COLORS.dunkelgelb
      break
    case 2:
      colors['-1'] = BMQ_COLORS.mittelgelb
      colors['-2'] = BMQ_COLORS.dunkelgelb
      break
    default:
      colors['-1'] = BMQ_COLORS.hellgelb
      colors['-2'] = BMQ_COLORS.mittelgelb
      colors['-3'] = BMQ_COLORS.dunkelgelb
      break
  }

  colors['0'] = BMQ_COLORS.keine_angabe_grau

  colors['-4'] = BMQ_COLORS.hellorange
  colors['-5'] = BMQ_COLORS.dunkelorange

  return colors
}

const white = 'rgba(255,255,255,1)'
// const black = 'rgba(0,0,0,1)'

export const COLORS_CONST = {
  transparent: 'rgba(0,0,0,0)', //Transparent
  axis: 'rgba(0,0,0,1)', //Achsenfarbe
  axisDash: 'rgba(150,150,150,1)', //Achsenfarbe gestrichelt
  background: BMQ_COLORS.hintergrund, //Hintergrund Schattierung
  barRowBackground: white, //Hintergrund Balkenzeile
  barRowBackgroundStroke: 'rgba(0,0,0,1)', //Farbe Helfer Rahmen
  tooltipBackground: 'rgba(255,255,255,1)',
  tooltipBorder: 'rgba(150,150,150,1)',
  border: BMQ_COLORS.raender,
  legendBorder: 'rgba(0,0,0,1)',
  arrowColorRight: BMQ_COLORS.dunkelblau,
  arrowColorLeft: BMQ_COLORS.dunkelgelb,
  header: 'rgb(216, 232, 200)',
  white: white
}

export const rowHeight = {
  big: 44,
  small: 24,
  line: 36
}

export const GENERAL_CONST = {
  svgWidth: 900, //Breite in Pixeln ohne Zoom
  //*Abstände
  topPadding: 0, //Abstand von allen Elementen zum oberen Rand
  leftPadding: 10, //Abstand zum linken Rand
  backgroundOffset: 1, //Abstand oben und unten zwischen HintegrundFärbung und Zeilenhöhe -> erzeugt weiße Lücken

  //*Größen Elemente
  yAxisStroke: { width: 3, color: COLORS_CONST.axis },
  xAxisStroke: { width: 2, color: COLORS_CONST.axis },
  xAxisTickStroke: { width: 1, color: COLORS_CONST.axisDash },
  xAxisTickHeight: 5, //Höhe der X-Achsen-Linien unterhalb der Achse an den Tick-Positionen
  xAxisTick: 20, //Abstand zwischen gestrichelten vertikalen Linien und Achsenbeschriftungen 0..20..40..60
  overflowTop: 5, //Überhang der Vertikal-Achsen über den oberen Chart-Bereich
  overflowBottom: 5, //Abstand zwischen X-Achse und unterstem Balken
  sizeLeftBenchmark: 7,
  sizeMiddleBenchmark: 6,
  //*Schriftgrößen
  fontSize: 14,
  fontSizeQuestion: 14,
  fontSizeGroup: 14,
  fontSizeTooltip: 14,
  fontSizeRight: 14,
  fontSizeTick: 14,
  fontSizeLegend: 11,
  fontSizeArrow: 14
}

//*Alle Einstellungen zu den Balkengrafiken können hier vorgenommen werden

export function LINE_CHART_CONSTANTS() {
  const leftPadding = 10 || GENERAL_CONST?.leftPadding
  const topPadding = null || GENERAL_CONST?.topPadding
  const benchmarkX = leftPadding
  const benchmarkSize = 7
  const benchmarkLabelOffset = 5
  const labelX = benchmarkX + benchmarkSize + benchmarkLabelOffset
  const labelAreaWidth = 380
  const chartAreaX = benchmarkX + labelAreaWidth
  const chartAreaY = topPadding
  const chartAreaWidth = 380

  const whitespaceOffset = 1 //Weiße Fläche zwischen den Hintergründen

  const totalWidth = chartAreaX + chartAreaWidth + leftPadding

  //*Label (Unterfrage)
  const labelFontSize = 14 || GENERAL_CONST?.fontSize
  const labelFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const labelAnchor = 'start' || GENERAL_CONST?.fontAnchor
  const labelBaseline = 'central' || GENERAL_CONST?.fontBaseline
  const maxLabelLength = 56

  const pointDiameter = 10
  const pointStrokeWidth = 1
  const pointStrokeWidthBig = 2
  const lineStrokeWidth = 0.75
  const lineStrokeWidthBig = 2

  const yAxisStroke = {
    width: null || GENERAL_CONST?.yAxisStroke.width,
    color: null || GENERAL_CONST?.yAxisStroke.color
  }
  const xAxisStroke = {
    width: null || GENERAL_CONST?.xAxisStroke.width,
    color: null || GENERAL_CONST?.xAxisStroke.color
  }
  const xAxisTickStroke = {
    color: null || GENERAL_CONST?.xAxisTickStroke.color,
    width: null || GENERAL_CONST?.xAxisTickStroke.width,
    dasharray: '5, 5',
    linecap: 'round'
  }
  const xAxisTickWidth = 20
  const xAxisOffset = 5
  const xAxisTickOffset = 4
  const xAxisTickHeight = 5 //Höhe der X-Achsen-Linien unterhalb der Achse an den Tick-Positionen
  const xAxisFontSize = 14 || GENERAL_CONST?.fontSize
  const xAxisFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const xAxisAnchor = 'middle' || GENERAL_CONST?.fontAnchor
  const xAxisBaseline = 'hanging' || GENERAL_CONST?.fontBaseline

  //*Legende
  const legendFontSize = 14 || GENERAL_CONST?.fontSize
  const legendFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const legendAnchor = 'start' || GENERAL_CONST?.fontAnchor
  const legendBaseline = 'hanging' || GENERAL_CONST?.fontBaseline
  const legendRectSize = 10
  const legendRectStroke = { width: 1, color: COLORS_CONST?.legendBorder }
  const legendRectTextOffset = 4
  const legendElementOffset = 15
  const legendAreaOffset = 10

  return {
    maxLabelLength,
    whitespaceOffset,
    //*Position
    benchmarkX, //X-Position, wo die Benchmarks anfangen
    benchmarkLabelOffset,
    labelX,
    chartAreaX,
    chartAreaWidth, //X-Achse Breite
    chartAreaY, //Y-Position, wo die Y-Achse (nach unten) anfängt
    //*Größen
    benchmarkSize,
    pointDiameter, //Punkt-Durchmesser
    pointStrokeWidth, //Dicke der Kreislinie der Datenpunkte
    pointStrokeWidthBig, //Dicke der Kreislinie der Datenpunkte der gewählten Gruppe (Benchmark)
    lineStrokeWidth, //Dicke der Verbindungslinien der Datenpunkte
    lineStrokeWidthBig, //Dicke der Verbindungslinien der Datenpunkte der gewählten Gruppe (Benchmark)
    //*Axes
    xAxisFontSize,
    xAxisFontFamily,
    xAxisAnchor,
    xAxisBaseline,
    //*Legende
    legendFontSize,
    legendFontFamily,
    legendAnchor,
    legendBaseline,
    legendRectStroke,
    legendRectSize,
    legendRectTextOffset,
    legendElementOffset,
    legendAreaOffset,
    totalWidth,
    xAxisStroke,
    xAxisTickStroke,
    yAxisStroke,
    xAxisOffset,
    xAxisTickOffset,
    xAxisTickWidth,
    xAxisTickHeight,
    labelFontSize,
    labelFontFamily,
    labelAnchor,
    labelBaseline
  }
}

export const LINE_CONST = LINE_CHART_CONSTANTS()

//*Alle Einstellungen zu den Balkengrafiken können hier vorgenommen werden

export function BAR_CHART_CONSTANTS() {
  //* Eigener Wert für Balkengrafik || Standardwert <- Eigener Wert wird bevorzugt
  //* Wenn der Standardwert genommen werden soll einfach "null || Standardwert" benutzen

  //*Gruppenname
  const groupNameFontSize = 14 || GENERAL_CONST?.fontSize
  const groupNameFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const groupNameAnchor = 'start' || GENERAL_CONST?.fontAnchor
  const groupNameBaseline = 'central' || GENERAL_CONST?.fontBaseline
  const maxGroupNameLength = 48

  //*SubquestionLabel (Unterfrage)
  const subquestionLabelFontSize = 14 || GENERAL_CONST?.fontSize
  const subquestionLabelFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const subquestionLabelAnchor = 'start' || GENERAL_CONST?.fontAnchor
  const subquestionLabelBaseline = 'central' || GENERAL_CONST?.fontBaseline
  const maxSubquestionLabelLength = 52

  //*ArrowText (links und rechts)
  const arrowTextLeft = 'selten'
  const arrowTextRight = 'häufig'
  const arrowTextFontSize = 14 || GENERAL_CONST?.fontSize
  const arrowTextFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const arrowTextAnchor = 'middle' || GENERAL_CONST?.fontAnchor
  const arrowTextBaseline = 'central' || GENERAL_CONST?.fontBaseline

  //*SideInfo (n und kA)
  const sideInfoFontSize = 14 || GENERAL_CONST?.fontSize
  const sideInfoFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const sideInfoAnchor = 'end' || GENERAL_CONST?.fontAnchor
  const sideInfoBaseline = 'central' || GENERAL_CONST?.fontBaseline

  //*Legende
  const legendFontSize = 14 || GENERAL_CONST?.fontSize
  const legendFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const legendAnchor = 'start' || GENERAL_CONST?.fontAnchor
  const legendBaseline = 'hanging' || GENERAL_CONST?.fontBaseline
  const legendRectSize = 10
  const legendRectStroke = { width: 1, color: COLORS_CONST?.legendBorder }
  const legendRectTextOffset = 4
  const legendElementOffset = 15
  const legendAreaOffset = 10

  //*Zeilenhöhe
  const rowHeightBig = 44 //Zeilenhöhe für Standardgruppe
  const rowHeightSmall = 24 //Zeilenhöhe für andere Gruppen
  //*Balkendetails
  const whitespaceOffset = 1 //Weiße Fläche zwischen den Hintergründen
  const backgroundOffset = 8 //Abstand zwischen Balken und weiß (Höhe des grauen Hintergrundes)
  const barHeightBig =
    rowHeightBig - 2 * backgroundOffset - 2 * whitespaceOffset
  const barHeightSmall =
    rowHeightSmall - 2 * backgroundOffset - 2 * whitespaceOffset
  const barStrokeDefault = { color: COLORS_CONST?.border, width: 1 }
  const barStrokeHighlight = { color: COLORS_CONST?.border, width: 3 }
  const mainOffset = 0 //Abstand zwischen Alle Balken und erster Gruppe
  const groupOffset = 6 //Abstand zwischen den Zeilen verschiedener Gruppen
  const subquestionOffset = 12 //Abstand zwischen den Zeilen verschiedener Unterfragen
  const subquestionOffsetIfDefaultGroupOnly = 6 //Abstand zwischen den Balkengruppen verschiedener Fragen, wenn nur "Alle" ausgewählt ist

  //*      -------------topPadding--------------------
  //* left-                    |---arrowArea---||--sideInfoHeader--|     <-headerArea
  //*padding|----labelArea----|----chartArea----|---sideInfoArea---|     <-graphArea
  //*       |benchmark - label| -----bars-------|    --n    --kA   |
  //*       |   bm - groupName| -----bars-------|    --n    --kA   |
  const leftPadding = null || GENERAL_CONST?.leftPadding
  const topPadding = null || GENERAL_CONST?.topPadding

  const headerAreaX = leftPadding
  const headerAreaY = topPadding

  const graphAreaX = leftPadding
  const labelAreaX = graphAreaX
  const benchmarkLabelX = labelAreaX
  const labelAreaWidth = 380
  const benchmarkLabelSize = 7
  const benchmarkLabelOffset = 4
  const subquestionLabelX =
    benchmarkLabelX + benchmarkLabelSize + benchmarkLabelOffset
  const benchmarkGroupNameX = labelAreaX + 40
  const benchmarkGroupNameSize = 6
  const benchmarkGroupNameOffset = 4
  const groupNameX =
    benchmarkGroupNameX + benchmarkGroupNameSize + benchmarkGroupNameOffset
  const chartAreaX = labelAreaX + labelAreaWidth
  const chartAreaWidth = 400
  const barMaxWidth = chartAreaWidth / 2
  const chartAreaXCenter = chartAreaX + chartAreaWidth / 2

  //*arrowArea
  const arrowAreaWidth = chartAreaWidth * 0.9
  const arrowBarHeight = 18
  const arrowTipHeight = arrowBarHeight * 1.8
  const arrowTipWidth = arrowBarHeight * 2.4
  const arrowAreaHeight = arrowTipHeight + 8
  const arrowTextY = headerAreaY + arrowTipHeight / 2
  const yAxisOverlapX = chartAreaXCenter
  const yAxisOverlapYTop = headerAreaY + (arrowTipHeight - arrowBarHeight) / 2 //oberes Ende der Pfeilmitte
  const yAxisOverlapYBottom = arrowAreaHeight + headerAreaY

  const graphAreaY = arrowAreaHeight + headerAreaY

  //*sideInfoHeader
  const sideInfoAreaX = chartAreaX + chartAreaWidth
  const sideInfoHeaderY = headerAreaY + arrowBarHeight
  const sideInfoAreaWidth = 130
  const sideInfoChartOffset = 0
  const sideInfoWidth_n = sideInfoAreaWidth / 2
  const sideInfoWidth_kA = sideInfoAreaWidth / 2
  const sideInfoX_n = sideInfoAreaX + sideInfoWidth_n + sideInfoChartOffset
  const sideInfoX_kA = sideInfoX_n + sideInfoWidth_kA
  const sideInfoHeaderText_kA = '(k.A.)'
  const sideInfoHeaderText_n = 'n'
  const sideInfoRightPadding = 10

  const totalWidth =
    sideInfoAreaX +
    sideInfoAreaWidth +
    sideInfoRightPadding +
    sideInfoChartOffset

  //*Axis
  const yAxisStroke = {
    width: null || GENERAL_CONST?.yAxisStroke.width,
    color: null || GENERAL_CONST?.yAxisStroke.color
  }
  const xAxisStroke = {
    width: null || GENERAL_CONST?.xAxisStroke.width,
    color: null || GENERAL_CONST?.xAxisStroke.color
  }
  const xAxisTickStroke = {
    color: null || GENERAL_CONST?.xAxisTickStroke.color,
    width: null || GENERAL_CONST?.xAxisTickStroke.width,
    dasharray: '5, 5',
    linecap: 'round'
  }
  const xAxisTickWidth = 20
  const xAxisOffset = 5
  const xAxisTickOffset = 4
  const xAxisTickHeight = 5 //Höhe der X-Achsen-Linien unterhalb der Achse an den Tick-Positionen
  const xAxisFontSize = 16 || GENERAL_CONST?.fontSize
  const xAxisFontFamily = 'Roboto' || GENERAL_CONST?.fontFamily
  const xAxisAnchor = 'middle' || GENERAL_CONST?.fontAnchor
  const xAxisBaseline = 'hanging' || GENERAL_CONST?.fontBaseline

  return {
    //*Gruppenname
    groupNameFontSize,
    groupNameFontFamily,
    groupNameAnchor,
    groupNameBaseline,
    maxGroupNameLength,

    //*Label (Unterfrage)
    subquestionLabelFontSize,
    subquestionLabelFontFamily,
    subquestionLabelAnchor,
    subquestionLabelBaseline,
    maxSubquestionLabelLength,

    //*ArrowText (links und rechts)
    arrowTextLeft,
    arrowTextRight,
    arrowTextFontSize,
    arrowTextFontFamily,
    arrowTextAnchor,
    arrowTextBaseline,

    //*Axes
    xAxisFontSize,
    xAxisFontFamily,
    xAxisAnchor,
    xAxisBaseline,

    //*SideInfo (n und kA)
    sideInfoFontSize,
    sideInfoFontFamily,
    sideInfoAnchor,
    sideInfoBaseline,

    //*Legende
    legendFontSize,
    legendFontFamily,
    legendAnchor,
    legendBaseline,
    legendRectStroke,
    legendRectSize,
    legendRectTextOffset,
    legendElementOffset,
    legendAreaOffset,

    //*Balkendetails
    whitespaceOffset,
    backgroundOffset,
    barHeightBig,
    barHeightSmall,
    barStrokeDefault,
    barStrokeHighlight,
    mainOffset,
    groupOffset,
    subquestionOffsetIfDefaultGroupOnly,
    subquestionOffset,

    leftPadding,
    topPadding,

    headerAreaX,
    headerAreaY,

    graphAreaX,
    labelAreaX,
    benchmarkLabelX,
    labelAreaWidth,
    benchmarkLabelSize,
    benchmarkLabelOffset,
    subquestionLabelX,
    benchmarkGroupNameX,
    benchmarkGroupNameSize,
    benchmarkGroupNameOffset,
    groupNameX,
    chartAreaX,
    chartAreaWidth,
    barMaxWidth,
    chartAreaXCenter,

    //*arrowArea
    arrowAreaWidth,
    arrowBarHeight,
    arrowTipHeight,
    arrowTipWidth,
    arrowTextY,
    arrowAreaHeight,
    yAxisOverlapX,
    yAxisOverlapYTop,
    yAxisOverlapYBottom,

    graphAreaY,

    //*sideInfoHeader
    sideInfoAreaX,
    sideInfoHeaderY,
    sideInfoAreaWidth,
    sideInfoHeaderText_kA,
    sideInfoHeaderText_n,
    sideInfoWidth_n,
    sideInfoWidth_kA,
    sideInfoX_n,
    sideInfoX_kA,

    totalWidth,
    xAxisStroke,
    xAxisTickStroke,
    yAxisStroke,
    xAxisOffset,
    xAxisTickOffset,
    xAxisTickWidth,
    xAxisTickHeight
  }
}

export const BAR_CONST = BAR_CHART_CONSTANTS()

//* 26.05.2022
export const ID = {
  separator: '_',
  subquestionLabel: 'subquestionLabel',
  groupName: 'groupName',
  benchmark: 'benchmark',
  n: 'n',
  kA: 'kA',
  bar: {
    arrowContainer: 'arrowContainer',
    arrow: 'arrow',
    subquestionContainer: 'subquestionContainer',
    rowContainer: 'rowContainer',
    item: 'barItem',
    itemOverlay: 'barItemOverlay',
    background: 'barBackground',
    rowBackground: 'barRowBackground',
    row: 'barRow',
    legend: 'barLegend'
  },
  line: {
    lineGroup: 'lineGroup',
    point: 'point',
    background: 'lineBackground',
    item: 'lineItem',
    line: 'line',
    legend: 'lineLegend'
  }
}

export const OFFSET = {
  question: {
    top: 0,
    bottom: 10
  },
  arrow: {
    top: 0,
    bottom: 5
  },
  xAxis: {
    bottom: 10
  }
}
