//@ts-check
import { LINE_CONST, ID, COLORS_CONST, BMQ_COLORS } from '../constants'
import { makeId, splitId } from '../helper'
import { useChartStore } from '../../stores/chart'
import { SVG } from '@svgdotjs/svg.js'

/**
 * @function highlightPoint
 * @param {object} point - SVG Point Objekt
 */

function highlightPoint(point) {
  const color = point.attr('stroke')
  point.fill(color)
  point.attr('fill-opacity', 1)
}

/**
 * @function deHighlightPoint
 * @param {object} point - SVG Point Objekt
 */

function deHighlightPoint(point) {
  const color = COLORS_CONST.white
  point.fill(color)
  point.attr('fill-opacity', 0)
}

/**
 * @function highlightLine
 * @param {object} line - SVG Line Objekt
 */

function highlightLine(line) {
  line.attr('stroke-width', LINE_CONST.lineStrokeWidthBig)
}

/**
 * @function deHighlightLine
 * @param {object} line - SVG Line Objekt
 */

function deHighlightLine(line) {
  line.attr('stroke-width', LINE_CONST.lineStrokeWidth)
}

export const updateLineView = (e) => {
  const chartStore = useChartStore()

  if (!SVG(e.target).attr('IDs')) return
  const IDs = JSON.parse(SVG(e.target).attr('IDs'))
  const svgContainer = chartStore.getSVGContainer

  const points = svgContainer.find('.' + ID.line.point)
  const lines = svgContainer.find('.' + ID.line.line)

  points.forEach((point) => {
    const pointGroupId = JSON.parse(point.attr('IDs')).gruppe_id
    if (IDs.gruppe_id == pointGroupId) {
      highlightPoint(point)
    } else {
      deHighlightPoint(point)
      point.addClass('faded')
    }
  })
  lines.forEach((line) => {
    const lineGroupId = JSON.parse(line.attr('IDs')).gruppe_id
    if (IDs.gruppe_id == lineGroupId) {
      highlightLine(line)
    } else {
      deHighlightLine(line)
      line.addClass('faded')
    }
  })
  //Legende:
  const legends = Array.from(document.getElementsByClassName(ID.line.legend)) //svgContainer.find('.' + ID.line.legend)

  legends.forEach((legend) => {
    const svgLegend = SVG(legend)
    const legendGroupId = JSON.parse(svgLegend.attr('IDs')).gruppe_id

    if (legendGroupId === IDs.gruppe_id) {
      svgLegend.attr('font-weight', 'bold')
    } else {
      svgLegend.attr('font-weight', 'normal')
    }
  })

  updateLineBenchmarks(IDs)
}
export const resetLineView = () => {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer
  const standardIDs = chartStore.getLinienStandardIDs

  const points = svgContainer.find('.' + ID.line.point)
  const lines = svgContainer.find('.' + ID.line.line)

  points.forEach((point) => {
    const pointGroupId = JSON.parse(point.attr('IDs')).gruppe_id
    if (pointGroupId === standardIDs.gruppe_id) {
      highlightPoint(point)
    } else {
      deHighlightPoint(point)
    }
    point.removeClass('faded')
  })

  lines.forEach((line) => {
    const lineGroupId = JSON.parse(line.attr('IDs')).gruppe_id
    if (lineGroupId === standardIDs.gruppe_id) {
      highlightLine(line)
    } else {
      deHighlightLine(line)
    }
    line.removeClass('faded')
  })

  //Legende:
  const legends = Array.from(document.getElementsByClassName(ID.line.legend)) //svgContainer.find('.' + ID.line.legend)

  legends.forEach((legend) => {
    const svgLegend = SVG(legend)
    const legendGroupId = JSON.parse(svgLegend.attr('IDs')).gruppe_id

    if (legendGroupId === standardIDs.gruppe_id) {
      svgLegend.attr('font-weight', 'bold')
    } else {
      svgLegend.attr('font-weight', 'normal')
    }
  })

  //Benchmarks rücksetzen:
  updateLineBenchmarks(standardIDs)
}

/**
 * Setzt die Standard Gruppe
 * @param {object} e - event
 * @param {import('../typedef').ID} [IDs] - IDs Objekt
 */
export const setLineView = (e, IDs) => {
  const chartStore = useChartStore()

  let ids = IDs
  if (e && e.target) {
    ids = SVG(e.target).attr('IDs')
      ? JSON.parse(SVG(e.target).attr('IDs'))
      : IDs
  }

  if (!ids) {
    // get IDs from parent <g> element
    let parentGroupElement = Array.from(SVG(e.target).parents()).find(
      (p) => p.type === 'g'
    )
    ids = SVG(parentGroupElement).attr('IDs')
      ? JSON.parse(SVG(parentGroupElement).attr('IDs'))
      : IDs
  }
  chartStore.linienStandardIDs = ids
  resetLineView()
}

/**
 * Aktualisiert alle Benchmark rects der Liniengrafik
 * @param {import('../typedef').ID} IDs - IDs Objekt
 */
const updateLineBenchmarks = (IDs) => {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer

  const benchmarks = chartStore.getLineBenchmarksByIDs(IDs)

  benchmarks.forEach((benchmark) => {
    if (benchmark.farbe_id) {
      const farbe = chartStore.getFarbeById(benchmark.farbe_id)

      const svgBenchmark = svgContainer.find(
        '#' + makeId([ID.benchmark, benchmark.unterfrage_id])
      )
      svgBenchmark.fill(farbe.farbe)
      svgBenchmark.opacity(1)
    }
  })
}
