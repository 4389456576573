//@ts-check
import { BAR_CONST, COLORS_CONST, LINE_CONST, OFFSET } from '../constants'
import { createSVG, updateSVG } from './svgElement'
import { yAxis } from './yAxis'

export const xAxis = {
  stroke: { width: 2, color: COLORS_CONST.axis },
  dashStroke: {
    width: 1,
    color: COLORS_CONST.axisDash,
    dasharray: '4',
    linecap: 'round'
  },
  tickHeight: 5
}

/**
 * @function drawXAxis
 * @param {number} xPos - Startpunkt (links) der X-Achse
 * @param {number} width - Gesamtbreite der X-Achse
 * @param {object} ticks - Objekt mit Min und Max Werten der X-Achse
 */
export function drawXAxis(xPos, width, ticks, line = false) {
  xPos = BAR_CONST.chartAreaX
  let yPosAxis = xAxis.stroke.width / 2

  const tickWidth = (width / (ticks.max - ticks.min)) * ticks.width
  let axisContainer = createSVG('svg-xAxis')
  let svgGroup = axisContainer.group()

  let tick = ticks.min
  let maxTextHeight = 0
  for (let index = xPos; index <= xPos + width; index += tickWidth) {
    // Beschriftung
    let tickText = [Math.abs(tick).toString()]

    //Striche nach unten
    svgGroup.add(
      axisContainer
        .line(index, 0, index, 0 + xAxis.tickHeight + xAxis.stroke.width)
        .stroke(tick === 0 ? yAxis.stroke : xAxis.stroke)
    )

    let yPosText = yPosAxis + xAxis.tickHeight + 6
    let textElement = drawAxisText(axisContainer, tickText, index, yPosText)
    svgGroup.add(textElement)
    if (tick == 0 && line == true) {
      let lineTextElement = drawAxisText(
        axisContainer,
        ['in %'],
        index + 35,
        yPosText + BAR_CONST.xAxisFontSize
      )
      svgGroup.add(lineTextElement)
    }
    maxTextHeight = Math.max(maxTextHeight, textElement.bbox().h)
    tick += ticks.width
  }

  //axis
  svgGroup.add(
    axisContainer
      .line(xPos - 1, yPosAxis, xPos + width + 1, yPosAxis)
      .stroke(xAxis.stroke)
  )
  updateSVG(
    axisContainer,
    svgGroup.bbox().w + LINE_CONST.totalWidth - xPos,
    svgGroup.bbox().h + OFFSET.xAxis.bottom
  )
  return { width: svgGroup.bbox().w + LINE_CONST.totalWidth - xPos }
}

/**
 * @function drawXAxis
 * @param {object} svgContainer - Startpunkt (links) der X-Achse
 * @param {number} xPos - Startpunkt (links) der X-Achse
 * @param {number} width - Gesamtbreite der X-Achse
 * @param {number} height - Höhe der gestrichelten Linien der X-Achse
 * @param {object} ticks - Objekt mit Min und Max Werten der X-Achse
 */
export function drawXAxisDashLines(svgContainer, xPos, width, height, ticks) {
  const tickWidth = (width / (ticks.max - ticks.min)) * ticks.width
  const yPos = BAR_CONST.whitespaceOffset
  const tickHeight = height - BAR_CONST.whitespaceOffset
  let tick = ticks.min
  for (let index = xPos; index <= xPos + width; index += tickWidth) {
    if (tick === 0) {
      svgContainer.line(index, 0, index, height).stroke(yAxis.stroke)
    } else if (!(tick === ticks.min || tick === ticks.max)) {
      // Gestrichelten Linien
      //tiefer anfangen
      svgContainer
        .line(index, yPos + 1, index, tickHeight)
        .stroke(xAxis.dashStroke)
    }
    tick += ticks.width
  }
}

/**
 * @function drawAxisText
 * @param {object} container - Der SVG() Container
 * @param {string[]} textArray - Beschriftung in Array
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate
 * @returns {object} - svgObject
 */
export function drawAxisText(container, textArray, xPos, yPos) {
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.xAxisFontSize
  const anchor = BAR_CONST.xAxisAnchor
  const baseline = BAR_CONST.xAxisBaseline

  const xAxisTextElement = svg
    .text(function (add) {
      textArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            // 'in %' kleiner
            size: el == 'in %' ? fontSize - 2 : fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .amove(xPos, yPos)
  // .addClass(ID.groupName)
  // .id(makeId([ID.groupName, groupId]))

  return xAxisTextElement
}
