var _ = require('lodash')
import { useChartStore } from '../stores/chart'
import { GENERAL_CONST } from './constants'

/**
 * Zeichnet ein Text-Element
 * @param {Array} text Array mit Textteilen pro Zeile
 * @param {Number} xPos X-Position
 * @param {Number} yPos Y-Position
 * @param {Number} fontSize Schriftgröße
 * @param {String} anchor Ankerpunkt Horizontal (start = Standard)
 * @param {String} baseline Ankerpunkt Vertikal (central = Standard)
 * @param {Number} rowHeight Zeilenhöhe für vertiakles Zentrieren
 */
export function drawText(
  text,
  xPos,
  yPos,
  fontSize,
  anchor = 'start',
  baseline = 'central',
  rowHeight = GENERAL_CONST.rowHeightSmallBar
) {
  const chartStore = useChartStore()

  const svg = chartStore.getSVGContainer
  var t = text
  var f = 1
  if (_.isArray(text)) {
    // t = text.join('\n')
    f = text.length
  }
  if (baseline == 'central') {
    yPos = yPos + rowHeight / (f + 1)
  }
  let tEl = null

  if (_.isArray(text)) {
    tEl = svg
      .text(function (add) {
        text.forEach((el) => {
          add
            .tspan(el)
            .font({
              family: 'Roboto',
              size: fontSize,
              anchor,
              'dominant-baseline': baseline
            })
            .newLine()
        })
      })
      .amove(xPos, yPos)
  } else {
    tEl = svg.text().amove(xPos, yPos)
    tEl.tspan(t).font({
      family: 'Roboto',
      size: fontSize,
      anchor,
      'dominant-baseline': baseline
    })
  }
  return tEl
}

// used later to move segment along its normal on hover and update text label
export function onMouseOver(evt) {
  const index = evt.target.getAttribute('index')
  const path = document.querySelector('#path-' + index)
  const label = document.querySelector('#label-' + index)

  const normal = path.getAttribute('data-normal')
  var dX = Math.cos(normal) * 5
  var dY = Math.sin(normal) * 5
  path.style.transform = 'translate(' + dX + 'px, ' + dY + 'px)'

  label.style['font-weight'] = 'bold'
  label.style.transform = 'translate(' + dX + 'px, ' + dY + 'px)'
}

// undo the above
export function onMouseOut(evt) {
  const index = evt.target.getAttribute('index')
  const path = document.querySelector('#path-' + index)
  const label = document.querySelector('#label-' + index)
  path.style.transform = 'none'
  label.style.transform = 'none'
  label.style['font-weight'] = 'normal'
}
//convenience function to get point on circumfrence of a given circle
export function circumPointFromAngle(cx, cy, r, a) {
  return [cx + r * Math.cos(a), cy + r * Math.sin(a)]
}
