import { LINE_CONST, ID } from '../constants'
import { makeId } from '../helper'
import { useChartStore } from '../../stores/chart'

/**
 * @function drawLineSubquestionLabel
 * @param {object} container - Der SVG() Container
 * @param {string[]} labelArray - Unterfragen-Label in Array
 * @param {number} subquestionId - ID der Unterfrage
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Höhe der Zeile
 * @returns {object} - bar-svgObject
 */
export function drawLineSubquestionLabel(labelArray, subquestionId, yPos) {
  const chartStore = useChartStore()

  const svgContainer = chartStore.getSVGContainer
  const rowHeight = chartStore.getRowHeightLine

  const fontSize = LINE_CONST.labelFontSize
  const anchor = LINE_CONST.labelAnchor
  const baseline = LINE_CONST.labelBaseline

  const xPos = LINE_CONST.labelX

  const subquestionLabelElement = svgContainer
    .text(function (add) {
      labelArray.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .addClass(ID.subquestionLabel)
    .id(makeId([ID.subquestionLabel, subquestionId]))

  const textBoxHeight = subquestionLabelElement.bbox().h
  const targetOffset = (rowHeight - textBoxHeight) / 2
  subquestionLabelElement.move(xPos, yPos + targetOffset)
  return subquestionLabelElement
}
