import { defineStore } from 'pinia'
import { useComStore } from './communication'
import { prepareAllTextsForSvg } from '../plugins/builder'
import validate from '../plugins/jsonValidator'
import { rowHeight, BAR_CONST, LINE_CONST } from '../plugins/constants'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    stickyAnimationActive: false,
    fullscreen: false,
    sidebarHidden: false,
    svgTooltipsActive: true
  }),
  getters: {},
  actions: {}
})
