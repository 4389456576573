export function getSector(center, radius, startAngle, endAngle) {
  const isCircle = endAngle - startAngle === 360
  if (isCircle) {
    endAngle--
  }
  const start = polarToCartesian(center, radius, startAngle)
  const end = polarToCartesian(center, radius, endAngle)
  //   for label
  const sectorCenter = polarToCartesian(
    center,
    radius / 2,
    startAngle + (endAngle - startAngle) / 2
  )

  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1
  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    1,
    end.x,
    end.y
  ]
  if (isCircle) {
    d.push('Z')
  } else {
    d.push('L', center.x, center.y, 'L', start.x, start.y, 'Z')
  }
  return { path: d.join(' '), sectorCenter }
}

function polarToCartesian(center, radius, angleInDegrees) {
  var radians = ((angleInDegrees - 90) * Math.PI) / 180
  return {
    x: Math.round(center.x + radius * Math.cos(radians)),
    y: Math.round(center.y + radius * Math.sin(radians))
  }
}
