<template>
  <v-card
    class="folder-button text-h6 mx-sm-4"
    @click="goTo(to)"
    min-height="100"
    min-width="120"
    tile
  >
    <div class="d-flex flex-column align-content-center">
      <div class="mt-2">
        <v-icon class="bmq-icon">mdi-chevron-right</v-icon>
      </div>
      <div>
        {{ name }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'FolderButton',
  components: {},
  props: {
    name: String,
    to: String,
    param: String
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {
    goTo(to) {
      if (!this.param) {
        this.$router.push({ name: to })
      } else {
        this.goToWithParam(to, this.param)
      }
    },
    goToWithParam(to, folder) {
      this.$router.push({ name: to, params: { folder } })
    }
  }
}
</script>

<style lang="scss">
@import '~/public/css/_variables.scss';

.folder-button {
  border: 1px solid $bmq-color-light !important;
  text-align: center;
}

.folder-button-text {
  height: 100%;
  vertical-align: middle;
}

.folder-button:hover {
  background-color: #005475;
  color: white;
}

.folder-button.welcome-text:hover {
  background-color: white;
  color: black;
}

.bmq-icon {
  background-color: #005475;
  border-radius: 50%;
  color: white !important;
}
</style>
