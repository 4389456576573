<template>
  <div>
    <v-row no-gutters class="mb-2">
      <v-col cols="6" class="file-name">
        <v-icon class="bmq-icon">mdi-chevron-right</v-icon>
        {{ fileName }}
      </v-col>
      <v-col cols="auto">
        <v-btn :href="downloadUrl" target="_blank">Herunterladen</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FileComponent',
  components: {},
  props: {
    fileUrl: String
  },
  data() {
    return {}
  },
  computed: {
    fileName() {
      if (!this.fileUrl || this.fileUrl == '') return ''
      return this.fileUrl.substring(this.fileUrl.lastIndexOf('/') + 1)
    },
    downloadUrl() {
      return 'download.php?file=' + this.fileUrl
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss">
.file-name {
  color: black;
}
</style>
