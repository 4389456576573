<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <v-card flat>
          <v-card-title>
            Seite nicht gefunden
          </v-card-title>
          <v-card-text>Diese Seite existiert nicht... </v-card-text>
          <v-card-actions>
            <v-btn :to="{ name: 'Login' }">Zurück zum Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UnknownPage',
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped></style>
