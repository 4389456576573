//@ts-check
import { BAR_CONST, ID } from '../constants'
import { makeId } from '../helper'
import { useChartStore } from '../../stores/chart'

/**
 * @function drawBarGroupName
 * @param {object} container - Der SVG() Container
 * @param {import('../typedef').Gruppenwert} gruppenWert - Gruppenname in Array
 * @param {number} yPos - Y-Koordinate
 * @param {number} rowHeight - Höhe der Zeile
 * @returns {object} - bar-svgObject
 */
export function drawBarGroupName(container, gruppenWert, yPos, rowHeight) {
  const chartStore = useChartStore()
  const svg = container //store.state.SVG_CONTAINER
  const fontSize = BAR_CONST.groupNameFontSize
  const anchor = BAR_CONST.groupNameAnchor
  const baseline = BAR_CONST.groupNameBaseline

  const xPos = BAR_CONST.groupNameX

  const gruppe = chartStore.getGruppeByGruppenWert(gruppenWert)

  const groupNameElement = svg
    .text(function (add) {
      gruppe.svgName.forEach((el) => {
        add
          .tspan(el)
          .font({
            size: fontSize,
            anchor,
            'dominant-baseline': baseline
          })
          .newLine()
      })
    })
    .addClass(ID.groupName)
    .id(makeId([ID.groupName, gruppenWert.gruppe_id]))

  const textBoxHeight = groupNameElement.bbox().h
  const targetOffset = (rowHeight - textBoxHeight) / 2
  groupNameElement.move(xPos, yPos + targetOffset)

  return groupNameElement
}
