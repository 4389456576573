//@ts-check
import { drawBarItem } from './barItem'
import { BAR_CONST } from '../constants'
import { useChartStore } from '../../stores/chart'
var _ = require('lodash')

/**
 * Zeichnet alle Balkenabschnitte für eine Zeile
 * @function drawBarItems
 * @param {object} container - SVG() Container Element
 * @param {import('../typedef').Wert[]} werte - Array of barItems
 * @param {number} yPos - Koordinaten für Y-Position
 * @param {number} rowHeight - Zeilenhöhe
 * @param {boolean} isDefaultGroup - Gehören die Items zur Standard Gruppe?
 * @param {import('../typedef').ID} IDs - Gehören die Items zur Standard Gruppe?
 */
export function drawBarItems(
  container,
  werte,
  yPos,
  rowHeight,
  isDefaultGroup,
  IDs
) {
  const barHeight = isDefaultGroup
    ? BAR_CONST.barHeightBig
    : BAR_CONST.barHeightSmall
  const xPos = BAR_CONST.chartAreaXCenter
  const gElement = container.group()
  const sortedWerte = sortWerteByOrder(werte)
  let offset = 0
  let pOffset = 0
  let nOffset = 0
  for (let index = 0; index < sortedWerte.length; index++) {
    const wert = sortedWerte[index]
    if (wert.reihenfolge === 0 || wert.relativ === 0) continue
    IDs.antwort_id = wert.antwort_id

    let drawValue =
      (wert.reihenfolge / Math.abs(wert.reihenfolge)) *
      wert.relativ *
      (BAR_CONST.barMaxWidth / 100)

    let { barItem, barItemOverlay } = drawBarItem(
      container,
      IDs,
      wert,
      drawValue,
      barHeight
    )
    if (drawValue >= 0) {
      offset = pOffset
      pOffset += drawValue
    } else {
      offset = nOffset + drawValue
      nOffset += drawValue
    }
    barItem.move(xPos + offset, yPos + (rowHeight - barHeight) / 2)
    barItemOverlay.move(xPos + offset, yPos + (rowHeight - barHeight) / 2)
    gElement.add(barItem)
    gElement.add(barItemOverlay)
  }
  return gElement
}

const sortWerteByOrder = (werte) => {
  const chartStore = useChartStore()
  const mapped = werte.map((w) => {
    return {
      ...w,
      reihenfolge: chartStore.getAntwortById(w.antwort_id).reihenfolge
    }
  })
  mapped.sort((a, b) => {
    return b.reihenfolge - a.reihenfolge
  })
  let zeroIndex = mapped.findIndex((a) => a.reihenfolge == 0)
  let positiveAnswers = mapped.slice(0, zeroIndex)
  let negativeAnswers = mapped.slice(zeroIndex)
  return [..._.reverse(positiveAnswers), ...negativeAnswers]
}
