<template>
  <v-card class="full-height-card">
    <v-card-subtitle>
      <v-row align="center">
        <v-col cols="auto">Fragen</v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-list dense>
        <v-list-item
          v-for="(q, index) in questions"
          :key="'q-' + index"
          :value="q"
          class="px-2"
          two-line
          @click="handleClick(q)"
          color="bmq-blue"
          :input-value="
            q.id === selectedQuestionId ? 'v-list-item--active' : ''
          "
        >
          <v-list-item-content>
            <v-list-item-title class="text-wrap bmq-text">{{
              q.frage
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { useChartStore } from '../stores/chart'
import { storeToRefs } from 'pinia'
import { onMounted, computed, ref, watch } from 'vue'

export default {
  name: 'QuestionSelect',
  setup() {
    const chartStore = useChartStore()
    const { fragen, selectedQuestionId } = storeToRefs(chartStore)

    const questions = computed(() => {
      return fragen.value?.map((f) => {
        let question = {}
        question.frage = f.praefix ? f.praefix + ': ' + f.frage : f.frage
        question.id = f.id
        return question
      })
    })

    function handleClick(question) {
      selectedQuestionId.value = question.id
    }

    return { questions, handleClick, selectedQuestionId }
  }
}
</script>
