import { useChartStore } from '../../stores/chart'
import { GENERAL_CONST, COLORS_CONST } from '../constants'
import { drawText } from '../svgBasics'
import { createSVG, updateSVG } from '../general/svgElement'
import { drawQuestion } from '../general/questionContainer'
import { getSector } from './pieHelper'
import { setHoverEffect } from '../helper'

/**
 *
 * @param {import('./typedef').Frage} frage
 */
export const drawPieChart = (frage) => {
  const chartStore = useChartStore()

  drawQuestion(frage)
  let svgContainer = createSVG()

  chartStore.svgContainer = svgContainer
  const radius = 190
  const center = {
    x: GENERAL_CONST.topPadding + 20 + radius,
    y: GENERAL_CONST.topPadding + 20 + radius
  }

  //* Bottom PIE layer
  const pieLayer = svgContainer.group()
  //* Middle Label layer
  const labelLayer = svgContainer.group()
  //* Top focusHelper layer
  const focusHelperLayer = svgContainer.group()

  // out actual data
  const werte = frage.werte

  // for each ratio, lets build our segment
  werte.forEach((wert, idx, arr) => {
    const roundedShare = Math.round(wert.relativ)
    // Get the angle of the sector
    wert.degrees = (wert.relativ / 100) * 360
    if (!idx) {
      wert.from = 0
      wert.to = wert.degrees
    } else {
      wert.from = arr[idx - 1].to
      wert.to = wert.from + wert.degrees
    }
    const { path, sectorCenter } = getSector(center, radius, wert.from, wert.to)

    const antwort = frage.antworten.find((a) => a.id === wert.antwort_id)
    const farbe = chartStore.getFarbeById(antwort.farbe_id)
    // create the element, set the d attr, data attrs and fill style
    let sector = svgContainer.path(path)
    sector.attr('data-label', antwort.antwort)
    sector.attr('data-value', wert.relativ)
    sector.attr('data-normal', sectorCenter)
    sector.attr('index', idx)
    sector.fill(farbe.farbe)
    sector.id('share-' + idx)

    pieLayer.add(sector)

    if (roundedShare >= 5) {
      let label = roundedShare + '%'
      var text = drawLabelInBox(label, sectorCenter.x, sectorCenter.y)

      text.attr('index', idx)
      text.id('label-' + idx)

      //TODO if text is too big, then what?
      labelLayer.add(text)
    }

    const focusHelper = sector.clone().fill('rgba(255,255,255,1)').opacity(0)
    focusHelper.id('overlay-' + idx)
    focusHelper.addClass('overlay')
    focusHelperLayer.add(focusHelper)

    // simple listener to move the path out along the normal when hovered
    focusHelper.on('mouseover mouseenter', onMouseOver)
    // text.on('mouseover mouseenter', onMouseOver)

    // clear transform when mouseout
    focusHelper.on('mouseout mouseleave', onMouseOut)
  })
  drawPieLegend(werte)
  updateSVG(svgContainer, 930, 500)
}

/**
 *
 * @param {import('../typedef').Werte} werte
 */
export const drawPieLegend = (werte) => {
  const chartStore = useChartStore()

  const svg = chartStore.getSVGContainer
  var top = GENERAL_CONST.topPadding
  const leftStart = 440
  var left = leftStart
  var absoluteTotal = werte.reduce((prev, cur) => {
    return prev + cur.absolut
  }, 0)
  var relativeTotal = '100%'
  const rectWidth = 14
  const rowHeight = 16
  let groupContainer = svg.group()
  groupContainer.id('pie-legend')

  //* Top focusHelper layer
  const focusHelperLayer = svg.group()

  const dataCount = werte.length

  for (let index = 0; index < dataCount; index++) {
    const wert = werte[index]
    const antwort = chartStore.getAntwortById(wert.antwort_id)
    const farbe = chartStore.getFarbeById(antwort.farbe_id)

    left = leftStart
    const label = antwort.antwort
    let group = svg.group()
    group.id('legend-' + index)

    let text = drawText(
      label + ` (${wert.absolut} | ${wert.relativ}%)`,
      left + rectWidth + 5,
      top,
      GENERAL_CONST.fontSize,
      'start',
      'central',
      rowHeight
    )
    let textCenter = text.bbox().y + text.bbox().h / 2

    let rect = svg
      .rect(rectWidth, rectWidth)
      .fill(farbe.farbe)
      .stroke({ width: 1, color: COLORS_CONST.legendBorder })
      .move(left, textCenter - rectWidth / 2)

    group.add(rect)
    group.add(text)

    group.attr('index', index)

    top += rowHeight * 1.5

    const focusHelper = group.clone().fill('rgba(255,255,255,1)').opacity(0)
    focusHelper.id('overlay-' + index)
    focusHelper.addClass('overlay')
    focusHelperLayer.add(focusHelper)
    focusHelper.on('mouseenter', onMouseOver)
    focusHelper.on('mouseleave', onMouseOut)

    groupContainer.add(group)
  }
  let total = drawText(
    `Gesamt: ${absoluteTotal} | ${relativeTotal}`,
    leftStart,
    top,
    GENERAL_CONST.fontSize,
    'start',
    'central',
    rowHeight
  )
  groupContainer.add(total)
}

export const drawLabelInBox = (label, xPos, yPos) => {
  const chartStore = useChartStore()

  const svg = chartStore.getSVGContainer
  const labelBox = svg.group()

  const background = svg
    .rect(10, 10)
    .fill('rgba(255,255,255)')
    .stroke({ width: 1, color: COLORS_CONST.legendBorder })

  var text = drawText(
    [label],
    xPos,
    yPos,
    GENERAL_CONST.fontSize,
    'middle',
    'central',
    GENERAL_CONST.fontSize
  )
  let box = text.bbox()
  background.size(box.w + 6, box.h + 6).move(box.x - 3, box.y - 3)
  labelBox.add(background)
  labelBox.add(text)
  return labelBox
}

// used later to move segment along its normal on hover and update text label
function onMouseOver(evt) {
  const chartStore = useChartStore()

  const svg = chartStore.getSVGContainer

  const index = evt.target.getAttribute('index')
  // const path = document.querySelector('#path-' + index)
  // const label = document.querySelector('#label-' + index)
  const legend = document.querySelector('#legend-' + index)
  const overlays = svg.find('.overlay')

  overlays.forEach((overlay) => {
    if (overlay.attr('index') != index) {
      setHoverEffect(overlay)
      // overlay.attr('fill', 'rgba(255, 255, 255, 0.3)')
    }
  })

  legend.style['font-weight'] = 'bold'
}

// undo the above
function onMouseOut(evt) {
  const chartStore = useChartStore()

  const svg = chartStore.getSVGContainer

  const index = evt.target.getAttribute('index')
  // const path = document.querySelector('#path-' + index)
  // const label = document.querySelector('#label-' + index)
  const legend = document.querySelector('#legend-' + index)

  const overlays = svg.find('.overlay')

  overlays.forEach((overlay) => {
    // overlay.attr('fill', 'rgba(255, 255, 255, 0)')
    setHoverEffect(overlay, true)
  })

  legend.style['font-weight'] = 'normal'
}
//convenience function to get point on circumfrence of a given circle
function circumPointFromAngle(cx, cy, r, a) {
  return [cx + r * Math.cos(a), cy + r * Math.sin(a)]
}
