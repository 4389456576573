import { defineStore } from 'pinia'
import { useComStore } from './communication'
import { prepareAllTextsForSvg } from '../plugins/builder'
import validate from '../plugins/jsonValidator'
import { rowHeight, BAR_CONST, LINE_CONST } from '../plugins/constants'

export const useChartStore = defineStore('chart', {
  state: () => ({
    // von JSON
    fragen: null,
    kategorien: null,
    farben: null,
    benchmarks: null,
    // Auswahl
    showNotAnswered: true,
    selectedQuestionId: null,
    selectedGroupIds: [],
    defaultGroupId: 0, //TODO zum getter machen
    // Für SVG
    svgContainer: null,
    linienStandardIDs: null,
    // groupname has more than 2 lines (bar only)
    groupsIdsWithBigGroupName: [],
    // svgTooltipContainer: null,
    totalWidthLine: LINE_CONST.totalWidth,
    totalWidthBar: BAR_CONST.totalWidth,
    stickyAnimation: false,
    zoomFactor: 1
  }),
  getters: {
    selectedQuestionIndex() {
      return this.fragen?.findIndex((f) => f.id === this.selectedQuestionId)
    },
    selectedQuestion() {
      return this.fragen?.find((f) => f.id === this.selectedQuestionId)
    },
    kategorienFiltered: (state) => {
      if (state.showNotAnswered) return state.kategorien
      return state.kategorien.map((category) => {
        return {
          ...category,
          gruppen: category.gruppen.filter((gruppe) =>
            ['k.A.', 'keine Angabe'].every(
              (filterName) => gruppe.name != filterName
            )
          )
        }
      })
    },
    getBenchmarkById: (state) => (id) => {
      return state.benchmarks.find((b) => b.id === id)
    },
    getLineBenchmarksByIDs() {
      return (IDs) => {
        let benchmarks = []
        this.selectedQuestion?.unterfragen.forEach((unterfrage) => {
          const gruppenWert = unterfrage.gruppen_werte.find(
            (g) => g.gruppe_id === IDs.gruppe_id
          )
          benchmarks.push({
            unterfrage_id: unterfrage.id,
            ...this.getBenchmarkById(gruppenWert.benchmark_id)
          })
        })
        return benchmarks
      }
    },
    getAntwortById() {
      return (id) => {
        return this.selectedQuestion?.antworten.find((a) => a.id === id)
      }
    },
    getFarbeById() {
      return (id) => {
        return this.farben.find((f) => f.id === id)
      }
    },
    getKategorieById: (state) => (id) => {
      return state.kategorien.find((k) => k.id === id)
    },
    getGruppeByGruppenWert: (state) => (gruppenWert) => {
      let kategorie = state.kategorien.find(
        (k) => k.id === gruppenWert.kategorie_id
      )
      return kategorie.gruppen.find((g) => g.id === gruppenWert.gruppe_id)
    },
    getGruppeIsSelectedById() {
      return (id) => {
        return this.selectedGroupIds.indexOf(id) >= 0
      }
    },
    //TODO für jeden tooltip infos hier holen, nicht über attribute, oder?
    getGruppenWertByIDs() {
      return (IDs) => {
        let frage = this.selectedQuestion
        let gruppenWert
        if (frage.unterfragen) {
          let unterfrage = frage.unterfragen.find(
            (u) => u.id === IDs.unterfrage_id
          )
          gruppenWert = unterfrage.gruppen_werte.find(
            (g) => g.gruppe_id === IDs.gruppe_id
          )
        } else {
          gruppenWert = frage.gruppen_werte.find(
            (g) => g.gruppe_id === IDs.gruppe_id
          )
        }
        return gruppenWert
      }
    },
    getBigLabel() {
      return !!this.selectedQuestion?.unterfragen?.filter((u) => u.bigLabel)
    },
    getBigGroupName() {
      return !!this.groupsIdsWithBigGroupName.some((big) =>
        this.selectedGroupIds.includes(big)
      )
    },
    getRowHeightLabel() {
      return this.getBigLabel ? rowHeight.big * 1.5 : rowHeight.big
    },
    getRowHeightGroupName() {
      return this.getBigGroupName ? rowHeight.small * 1.5 : rowHeight.small
    },
    getRowHeightLine: (state) => {
      return state.bigLabel ? rowHeight.line * 1.5 : rowHeight.line
    },
    getLinienStandardIDs: (state) => {
      return state.linienStandardIDs
    },
    // getSVGTooltipContainer: (state) => {
    //   return state.svgTooltipContainer
    // },
    getSVGContainer: (state) => {
      return state.svgContainer
    }
  },
  actions: {
    initStoreWithJson() {
      const comStore = useComStore()
      const sourceJson = comStore.sourceJson.data

      const validationResult = validate(sourceJson)
      if (!validationResult.valid)
        console.error(
          'Das JSON erfüllt nicht die Anforderungen, es kann zu Fehlern kommen.',
          validationResult
        )
      let { fragen, kategorien, groupsIdsWithBigGroupName } =
        prepareAllTextsForSvg(sourceJson)

      this.fragen = fragen
      this.kategorien = kategorien
      this.groupsIdsWithBigGroupName = groupsIdsWithBigGroupName

      let defaultGroupId = 0
      for (let index = 0; index < kategorien.length; index++) {
        const kategorie = kategorien[index]
        let standard = kategorie.gruppen.find((gruppe) => gruppe.standard)
        if (standard) {
          defaultGroupId = standard.id
          kategorie.standard = true
          break
        }
      }
      this.defaultGroupId = defaultGroupId
      this.farben = sourceJson.farben
      this.benchmarks = sourceJson.benchmarks
      this.selectedGroupIds = [defaultGroupId]
      this.selectedQuestionId = fragen[0].id
    }
  }
})
