//@ts-check

import { drawBarItems } from './barItems'
import { drawBarRowBackground } from './barRowBackground'
import { drawBarBackground } from './barBackground'
import { drawArrowHeader } from './arrowHeader'

import { drawBarBenchmarkAndText } from './barBenchmarkAndText'
import { drawSideInfoText } from './sideInfoText'
import { drawBarLegend } from './barLegend'
import { useChartStore } from '../../stores/chart'
import { useSettingsStore } from '../../stores/settings'
import { makeId } from '../helper'
import { BAR_CONST, ID } from '../constants'
import { drawXAxis, drawXAxisDashLines } from '../general/xAxis'
import {
  createSVG,
  updateSVG,
  updateChartContainerDimensions
} from '../general/svgElement'
import { drawQuestion } from '../general/questionContainer'
import { drawScrollHelper } from '../general/scrollHelper'
import { drawBarSubquestionLabel } from './barSubquestionLabel'

/**
 * Zeichnet die Balkengrafik
 * @function drawBarChart
 * @param {import('../typedef').Frage} frage - Frage
 */
export const drawBarChart = (frage) => {
  const chartStore = useChartStore()
  const settingsStore = useSettingsStore()
  /**
   * @type {import('../typedef').ID} IDs
   */
  var IDs = {}
  IDs.frage_id = frage.id

  const antworten = frage.antworten
  const unterfragen = frage.unterfragen

  const unterfragenAnzahl = unterfragen ? unterfragen.length : 1

  let yPosStart = 0
  let { height: questionHeight } = drawQuestion(frage)
  let arrowHeight =
    drawArrowHeader(questionHeight, frage.pfeile) + questionHeight

  //* Alle Unterfragen durchgehen (mind. 1 mal, falls keine Unterfragen)

  for (
    let unterfragenIndex = 0;
    unterfragenIndex < unterfragenAnzahl;
    unterfragenIndex++
  ) {
    const unterfrage = unterfragen ? unterfragen[unterfragenIndex] : null
    IDs.unterfrage_id = unterfrage?.id
    const gruppenWerte = unterfrage
      ? unterfrage.gruppen_werte
      : frage.gruppen_werte

    const subquestionOffset =
      gruppenWerte.length == 1
        ? BAR_CONST.subquestionOffsetIfDefaultGroupOnly
        : BAR_CONST.subquestionOffset

    //* Drawing
    let yPos = yPosStart
    let lastCategory = ''

    for (let rowIndex = 0; rowIndex < gruppenWerte.length; rowIndex++) {
      const gruppenWert = gruppenWerte[rowIndex]
      if (!chartStore.getGruppeIsSelectedById(gruppenWert.gruppe_id)) {
        // Gruppe überspringen
        continue
      }
      const category = chartStore.getKategorieById(
        gruppenWert.kategorie_id
      ).name

      IDs.gruppe_id = gruppenWert.gruppe_id
      IDs.kategorie_id = gruppenWert.kategorie_id

      const groupId = gruppenWert.gruppe_id
      const isDefaultGroup = chartStore.defaultGroupId === groupId

      const svgElementId = makeId([
        ID.bar.rowContainer,
        IDs.frage_id,
        unterfrage?.id,
        groupId
      ])
      var svgClass = []
      if (isDefaultGroup) {
        svgClass.push(ID.bar.subquestionContainer)
        if (settingsStore.stickyAnimationActive) svgClass.push('sticky')
      }

      let svgContainer = createSVG(svgElementId, svgClass)
      let svgGroup = svgContainer.group()
      // let svgGroup = svgContainer.findOne('#' + svgGroupId) //SVG-G für gesamte Zeile

      let rowHeight = isDefaultGroup
        ? chartStore.getRowHeightLabel
        : chartStore.getRowHeightGroupName

      //Abstand nach unten zu nächster Zeile
      //Standard = 6 (zwischen kleinen Balken)
      let bottomOffset =
        rowIndex === gruppenWerte.length - 1 ? subquestionOffset : 6
      // Bei gleicher Kategorie kleiner
      let nextCategory =
        rowIndex < gruppenWerte.length - 1
          ? gruppenWerte[rowIndex + 1].kategorie_id
          : ''
      bottomOffset =
        gruppenWert.kategorie_id === nextCategory
          ? bottomOffset - 5
          : bottomOffset
      let topOffset = 0
      let background = drawBarRowBackground(
        svgContainer,
        gruppenWert,
        yPos,
        unterfrage?.id,
        rowHeight + bottomOffset
      )
      svgGroup.add(background)

      if (category != lastCategory && rowIndex > 0) {
        let categoryLabel = svgContainer.text('---' + category + '---').font({
          size: 10,
          anchor: 'start',
          style: 'italic',
          'dominant-baseline': 'hanging'
        })

        topOffset = categoryLabel.bbox().h

        categoryLabel.amove(BAR_CONST.groupNameX - 8)
        svgGroup.add(categoryLabel)
        background.height(background.height() + topOffset)
      }

      lastCategory = category
      //* Wenn Unterfragen (Standard Gruppe), dann Unterfrage und Benchmark, sonst Gruppenname und Benchmark
      let benchmarkAndTextElement =
        isDefaultGroup && unterfrage
          ? drawBarBenchmarkAndText(
              svgContainer,
              unterfrage,
              gruppenWert,
              yPos + topOffset,
              rowHeight
            )
          : drawBarBenchmarkAndText(
              svgContainer,
              null,
              gruppenWert,
              yPos + topOffset,
              rowHeight
            )

      svgGroup.add(benchmarkAndTextElement)

      let sideInfoTextElement = drawSideInfoText(
        svgContainer,
        [gruppenWert.gesamtAntworten],
        [gruppenWert.gesamtKeineAngabe],
        yPos + topOffset,
        rowHeight
      )
      svgGroup.add(sideInfoTextElement)
      svgGroup.add(drawBarBackground(svgContainer, yPos + topOffset, rowHeight))

      let barItems = drawBarItems(
        svgContainer,
        gruppenWert.werte,
        yPos + topOffset,
        rowHeight,
        isDefaultGroup,
        IDs
      )
      svgGroup.add(barItems)

      // let groupOffset = BAR_CONST.groupOffset //isDefaultGroup ? BAR_CONST.mainOffset : 0
      // yPos += rowHeight + groupOffset
      drawXAxisDashLines(
        svgContainer,
        BAR_CONST.chartAreaX,
        BAR_CONST.chartAreaWidth,
        svgGroup.bbox().h,
        { min: -100, max: 100, width: 20 }
      )
      let style = isDefaultGroup ? { top: arrowHeight + 'px' } : null
      updateSVG(svgContainer, BAR_CONST.totalWidth, svgGroup.bbox().h, style)
    }
  }
  updateChartContainerDimensions()

  drawXAxis(BAR_CONST.chartAreaX, BAR_CONST.chartAreaWidth, {
    min: -100,
    max: 100,
    width: 20
  })
  drawBarLegend(antworten)
  drawScrollHelper()
}
