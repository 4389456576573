import * as SVG from '@svgdotjs/svg.js'
/**
 * Erstellt ein vertiakl zentriertes, gefülltes und umrandetes Quadrat und gibt dieses zurück.
 * Es wird nicht automatisch dem DOM hinzugefügt (gezeichnet).
 * @function drawSquare
 * @param {number} xPos - X-Koordinate
 * @param {number} yPos - Y-Koordinate der Mitte
 * @param {number} size - Breite/Höhe des Quadrats
 * @param {string} color - Farbe des Quadrats
 * @param {import('@svgdotjs/svg.js').StrokeData} stroke - Rand des Quadrats
 * @returns {object} - bar-svgObject
 */
export function drawSquare(xPos, yPos, size, color, stroke) {
  const rectElement = new SVG.Rect()
  try {
    rectElement
      .size(size, size)
      .fill(color)
      .stroke(stroke)
      .move(xPos, yPos - size / 2)
  } catch (error) {
    console.error(
      'Rect could not be drawn. Invalid Input. Input given for xPos, yPos, size, color, stroke : ',
      xPos,
      yPos,
      size,
      color,
      stroke
    )
  }
  return rectElement
}
