//@ts-check
import { BAR_CONST, ID } from '../constants'
import * as TOOLTIP from '../svgTooltip'
import { useChartStore } from '../../stores/chart'

/**
 * @function drawBarItem
 * @param {object} container - Der SVG() Container
 * @param {import('../typedef').ID} IDs - Das Objekt selbst
 * @param {import('../typedef').Wert} wert - Das Objekt selbst
 * @param {number} width - Das Objekt selbst
 * @param {number} height - Höhe des Balkenabschnitts
 * @returns {object} - bar-svgObject
 */
export function drawBarItem(container, IDs, wert, width, height) {
  const chartStore = useChartStore()
  const antwort = chartStore.getAntwortById(wert.antwort_id)
  const farbe = chartStore.getFarbeById(antwort.farbe_id)

  let barItem = container
    .rect(Math.abs(width), height)
    .fill(farbe.farbe)
    .stroke(BAR_CONST.barStrokeDefault)
    .addClass(ID.bar.item)
    .attr('IDs', JSON.stringify(IDs))
  let barItemOverlay = container
    .rect(Math.abs(width), height)
    .fill('rgba(255,255,255,1)')
    .opacity(0)
    .addClass(ID.bar.itemOverlay)
    .attr('IDs', JSON.stringify(IDs))
  barItemOverlay.on('mouseenter mouseover', TOOLTIP.init)
  barItemOverlay.on('mouseleave', TOOLTIP.hide)
  return { barItem, barItemOverlay }
}
